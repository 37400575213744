import axios from "axios";
import store from "../store";
import auth from "../plugins/authService";
import moment from "moment";
import { setSnackAction } from "../store/actions/snackActions";

function errorResponseHandler(error) {
  console.log(JSON.stringify(error));
  // check for errorHandle config
  if (
    error.config.hasOwnProperty("errorHandle") &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  if (error.response) {
    // server responsed with an error
    console.log(error.response);
    if (error.response.status === 401) {
      store.dispatch(setSnackAction(`Unauthorised request to ${error.response.config.url}`, "error"));
      return Promise.reject(error);
    }
    if (error.response.status === 422) {
      store.dispatch(setSnackAction(error.response.data, "error"));
      return Promise.reject(error);
    }

    store.dispatch(setSnackAction(error.response.data.title, "error"));
    return Promise.reject(error);
  } else if (error.request) {
    // request was made but no response received
    store.dispatch(setSnackAction("No response from server", "error"));
    return Promise.reject(error);
  } else {
    // something else happened before making the request
    store.dispatch(
      setSnackAction(error.message || "Something went wrong.", "error")
    );
    return Promise.reject(error);
  }
}

//const _axios = axios.create();

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    if (config.url.endsWith("/vars")) return config;

    var storedToken = localStorage.getItem("tokenResponse");

    var token = JSON.parse(storedToken);

    var minsUntilExpiry = moment(token.expiresOn).diff(moment(), "minutes");

    //if the token only has 50 mins or less left then grab another one (this will ensure that the token should be refreshed every 10 mins)
    if (minsUntilExpiry < 50) {
      auth.initialiseUserSession();
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

_axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

_axios.interceptors.response.use((response) => response, errorResponseHandler);

export default _axios;
