import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import axios from "../../plugins/axios";
import MyCKEditor from "../../ckeditor/MyCKEditor";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/reducers/configReducer";
import InsertSection from "./InsertSection";
import InsertPlaceholder from "./InsertPlaceholder";

import {
    Box,
    Button as MuiIconButton,
    TextField
} from "@material-ui/core";

import { Save as SaveIcon } from "@material-ui/icons";

import { grey } from "@material-ui/core/colors";

const IconButton = styled(MuiIconButton)`
  padding: 2px;
  margin-top: -2px;
  margin-left: 2px;
  min-width: 0;
  color: ${grey[700]};
`;

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const TemplateEditor = ({ template, setTemplate, onSave }) => {

    const [templateInfo, setTemplateInfo] = useState(template);
    const [saving, setSaving] = useState(false);
    const [editor, setEditor] = useState();   

    const styles = { targetDiv: { height: "calc(100vh - 240px)" } };

    useEffect(() => {
        let html = "";

        if (templateInfo.html) {
            html = templateInfo.html;
        }

        if (editor)
            editor.setData(html);

    }, [editor, templateInfo.html]);

    useEffect(() => {
        const getTemplateInfo = async () => {
            var response = await axios.get(
                `${apiUrl}/gettemplate/${template.templateId}`
            );
            setTemplateInfo({ ...templateInfo, html: response.data.html });
        }

        if (!templateInfo.templateId) {
            var newtemplate = {
                name: "",
            };

            setTemplateInfo(newtemplate);
        } else if (!templateInfo.html) {
            getTemplateInfo();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setTemplateName = (e) => {
        setTemplateInfo({ ...templateInfo, name: e.target.value });
    };

    const save = async () => {
        let html = editor.getData();

        if (html === templateInfo.html && templateInfo.name === template.name) {
            onSave();
            return;
        }

        var saveData = {
            ...templateInfo,
            html,
        };

        setSaving(true);

        var response = await axios.post(`${apiUrl}/savetemplate`, saveData);

        setTemplate(response.data);

        setSaving(false);

        onSave();
    };

    return (
        <React.Fragment>
            <Box display="flex" flexDirection="column" style={styles.targetDiv}>
                <Box display="flex" alignItems="center" gridGap="5px">
                    <TextField
                        variant="outlined"
                        size="small"
                        label="Template Name"
                        inputProps={{ maxLength: 255 }}
                        value={templateInfo.name}
                        onChange={setTemplateName}
                        m={2}
                        style={{ width: "300px" }}
                    />
                    {
                        editor &&
                        <IconButton disabled={saving} disableRipple={false}>
                            <SaveIcon onClick={save} />
                        </IconButton>
                    }
                </Box>
                <Box display="flex" gridGap="5px">
                    <InsertSection editor={editor} />            
                    <InsertPlaceholder editor={editor} />                          
                </Box>
                <MyCKEditor type="template" setEditor={setEditor} editor={editor} />
            </Box>
        </React.Fragment>
    );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(null, mapDispatchToProps)(TemplateEditor);

