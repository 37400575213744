import React from 'react';
import {
    Card,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {format} from 'date-fns'

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(4)
    }
}))

const rowsPerPageOptions = [5, 10, 25]

function MyVisits({visits, page, rowsPerPage, onPageChange, onRowsChange}) {
    const classes = useStyles()

    const handleChangePage = (event, newPage) => {
        onPageChange(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        onRowsChange(parseInt(event.target.value, 10));
        onPageChange(0);
    };
    
    return (
        <Card className={classes.card}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Site</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {visits?.results.map(v => (
                            <TableRow key={v.id}>
                                <TableCell>{v.accountName}</TableCell>
                                <TableCell>{v.subActivity}</TableCell>
                                <TableCell>{v.description}</TableCell>
                                <TableCell>{format(new Date(v.visitDate), 'dd/MM/yyyy HH:mm')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={visits?.totalCount ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Card>
    );
}

export default MyVisits;