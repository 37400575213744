import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../../plugins/axios";

import {
    TextField
} from "@material-ui/core";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const EmployeeLookup = ({ question, updateAnswer }) => {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let active = true;

        if (!input || input.length < 3) {
            setOptions([]);
            return undefined;
        }
        (async () => {
            setLoading(true);
            const employees = (
                await axios.get(`${apiUrl}/getemployees/` + encodeURIComponent(input))
            ).data;
            if (active) {
                setOptions(employees);
            }
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [open, input]);


    return (
        <Autocomplete
            freeSolo
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.text}
            onChange={(e, v) => updateAnswer(question.questionId, v && v.value)}
            options={options}
            loading={loading}
            onInputChange={(e, val, reason) => reason === "input" && setInput(val)}
            renderInput={(params) => {
                params.inputProps.autoComplete = "new-password";
                return <TextField {...params} fullWidth />;
            }}
        />
    );
}

export default EmployeeLookup;
