import React, { useState, useEffect } from "react";
import axios from "../../plugins/axios";

import {
    FormControl,
    FormHelperText,
    Select,
    MenuItem
} from "@material-ui/core";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const ContactLookup = ({ question, updateAnswer, accountId }) => {

    const [contacts, setContacts] = useState();   

    useEffect(() => {

        if (!accountId)
            return;

        const fetchContacts = async () => {
            let response = await axios.get(`${apiUrl}/getContacts/${accountId}`)
            setContacts(response.data);
        }

        fetchContacts();
    }, [accountId]);

    if (!accountId)
        return null;

    if (!contacts)
        return "searching ...";

    if (contacts.length === 0)
        return "no known contacts";

    return (
        <FormControl fullWidth error={!!question.error}>
            <Select
                displayEmpty
                value={question.answer || ""}
                onChange={e => updateAnswer(question.questionId, e.target.value)}
            >
                <MenuItem value="">select ...</MenuItem>
                {contacts.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}                
            </Select>
            {question.error && <FormHelperText>{question.error}</FormHelperText>}
        </FormControl>
    );
}


export default ContactLookup;
