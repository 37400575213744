import React, { useState, useEffect } from 'react';
import {
    Box,
    Tab,
    Tabs,
} from "@material-ui/core";
import TabPanel from "../TabPanel";
import Forms from "./Forms";
import Lists from "./Lists";
import Prospects from "./Prospects";
import BDEMappings from "./BDEMappings";
import JobTitles from "./JobTitles";
import Stats from "./Stats";
import Counts from "./Counts";
import Campaigns from "./Campaigns";
import { useGetFormsQuery } from "../../store/slices/pardotApiSlice";
 
const Marketing = () => {    

    const [currentTab, setCurrentTab] = useState("prospects");
    const [form, setForm] = useState(null);
    const [list, setList] = useState(null);
    const [formFilter, setFormFilter] = useState("");
    const [campaignFilter, setCampaignFilter] = useState("");
    const [listFilter, setListFilter] = useState("");
    const [jobTitleFilter, setJobTitleFilter] = useState("");

    const { data: forms } = useGetFormsQuery();

    useEffect(() => {
        if (form || list)
            setCurrentTab("prospects");
    }, [form, list]);

    const getFormName = id => {
        let form = forms.find(f => f.id === id);
        return form?.name || "..";
    };

    const clear = () => {
        setForm(null);
        setList(null);
    }

    const setFormAction = f => {
        setList(null);
        setForm(f);
    }

    const setListAction = l => {
        setForm(null);
        setList(l);
    }


    return (
        <Box display="flex" flexDirection="column" style={{ paddingTop: '10px' }}>            
            <Tabs value={currentTab} onChange={(e, x) => setCurrentTab(x)} style={{ marginBottom: '10px' }}>
                <Tab value="forms" label="Forms" />
                <Tab value="campaigns" label="Campaigns" />
                <Tab value="lists" label="Lists" />
                <Tab value="prospects" label="Prospects" />
                <Tab value="mappings" label="BDE Mappings" />
                <Tab value="stats" label="Stats" />
                <Tab value="counts" label="Counts" />
                <Tab value="jobtitles" label="Job Titles" />
            </Tabs>            
            <TabPanel value={currentTab} index="forms" >                
                <Forms setForm={setFormAction} filter={formFilter} setFilter={setFormFilter} />
            </TabPanel>
            <TabPanel value={currentTab} index="campaigns" >
                <Campaigns setForm={setFormAction} filter={campaignFilter} setFilter={setCampaignFilter} />
            </TabPanel>
            <TabPanel value={currentTab} index="lists" >
                <Lists setList={setListAction} filter={listFilter} setFilter={setListFilter} />
            </TabPanel>
            <TabPanel value={currentTab} index="prospects">
                <Prospects form={form} getFormName={getFormName} list={list} clear={clear} />                
            </TabPanel>
            <TabPanel value={currentTab} index="mappings">
                <BDEMappings />
            </TabPanel>
            <TabPanel value={currentTab} index="stats">
                <Stats />
            </TabPanel>
            <TabPanel value={currentTab} index="counts">
                <Counts />
            </TabPanel>
            <TabPanel value={currentTab} index="jobtitles">
                <JobTitles filter={jobTitleFilter} setFilter={setJobTitleFilter} />
            </TabPanel>
        </Box>
    )
};

export default Marketing;
