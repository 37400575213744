import React, { useEffect, useState } from 'react';
import axios from '../plugins/axios';
import Box from "@material-ui/core/Box";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const RecordingPlayer = ({ match }) => {

    const { recordingSid } = match.params;

    const [recordingUrl, setRecordingUrl] = useState();

    const [checking, setChecking] = useState(true);

    useEffect(() => {

        const getUrl = async () => {
            let response = await axios.get(`${apiUrl}/recording/${recordingSid}`);
            setRecordingUrl(response.data);
            setChecking(false);
        };

        getUrl();

    }, [recordingSid]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%">
            {checking && `Looking for recording ${recordingSid}`}
            {!checking && !recordingUrl && `Recording ${recordingSid} not found`}
            {
                recordingUrl &&
                <audio controls>
                    <source src={recordingUrl} type="audio/mp3" />
                </audio>
            }
        </Box>
    );
}

export default RecordingPlayer;
