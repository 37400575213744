import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL

const fetchAccountFiles = async (accountId, fileType, documentType) => {
    try {
        const response = await axios.get(`${apiUrl}/getaccountfiles/${accountId}/${fileType}/${documentType}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch documents.");
    }
};

const getAnswers = async (externalId) => {
    try {
        const response = await axios.get(`${apiUrl}/gettemplateanswers/${externalId}`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch answers.");
    }
}

const saveAnswers = async (externalId, answers) => {
    try {
        await axios.post(`${apiUrl}/savetemplateanswers/${externalId}`, answers);       
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch answers.");
    }
}

const fetchManualAndHandbookFiles = async (accountId) => {
    try {
        const response = await axios.get(`${apiUrl}/getmanualandhandbookitems/${accountId}/`);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not fetch documents.");
    }
};

const uploadAccountFile = async (
    formData,
    accountId,
    fileType,
    documentType
) => {
    try {
        const response = await axios.post(
            `${apiUrl}/adddocument/${documentType}/${fileType}/?accountId=${accountId}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

const deleteAccountFile = async (formData, documentType) => {
    try {
        const response = await axios.post(`${apiUrl}/deleteDocument/${documentType}`, formData);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not delete Account File.");
    }
};

const updateAccountFile = async (formData, documentType) => {
    try {
        const response = await axios.post(`${apiUrl}/updateDocument/${documentType}`, formData);
        return response.data;
    } catch (e) {
        console.error(e);
        return Promise.reject(e?.message || "Could not update Account File.");
    }
};

const documentService = {
    fetchAccountFiles,
    uploadAccountFile,
    deleteAccountFile,
    updateAccountFile,
    fetchManualAndHandbookFiles,
    getAnswers,
    saveAnswers
};

export default documentService;
