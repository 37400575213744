import { configureStore } from '@reduxjs/toolkit'
import { pardotApi } from './slices/pardotApiSlice'
import userReducer from './reducers/userReducer';
import snackReducer from "./reducers/snackReducer";
import configReducer from "./reducers/configReducer";
import targetReducer from "./reducers/targetReducer";
import registrationReducer from './slices/registrationSlice'

const store = configureStore({    
    reducer: {
        [pardotApi.reducerPath]: pardotApi.reducer,
        userReducer,
        snackReducer,
        configReducer,
        targetReducer,
        registrationReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(pardotApi.middleware)
})

export default store;

