import React from 'react';

import {
    TableHead,
    TableRow,
    TableCell
} from "@material-ui/core";


const TargetTableHeader = ({ view }) => {

    return (
        <TableHead>
            <TableRow>
                <TableCell>Objective</TableCell>
                <TableCell align="right">Target</TableCell>
                <TableCell align="right">Last Month</TableCell>
                <TableCell align="right">This Month</TableCell>
                {
                    view === "scorecard" &&
                    <React.Fragment>
                        <TableCell>FCA (Fact, Cause, Action)</TableCell>
                        <TableCell>Previous Action</TableCell>
                        <TableCell />
                    </React.Fragment>
                }
            </TableRow>
        </TableHead>
    )
}

export default TargetTableHeader