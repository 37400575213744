import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import axios from "../plugins/axios";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import moment from "moment";

import {
  Box,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Button,
} from "@material-ui/core";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const WeeklySummary = ({ user }) => {
  const [weeklySummary, setWeeklySummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().startOf("isoWeek"));

  const getWeeklySummary = useCallback(async () => {
    if (!user) return;

    try {
      setLoading(true);
      var response = await axios.get(
        `${apiUrl}/weeklysummary/${selectedDate.format("YYYY-MM-DD")}`
      );
      console.log("summary", response);
      setWeeklySummary(response.data);
    } finally {
      setLoading(false);
    }
  }, [user, selectedDate]);

  useEffect(() => {
    getWeeklySummary();
  }, [user, getWeeklySummary, selectedDate]);

  const addDays = (days) => {
    setSelectedDate(moment(selectedDate).add(days, "days"));
  };

  if (!user) return null;

  if (!user && !loading && !weeklySummary)
    return <h1>Employee not registered in Salesforce!</h1>;

  return (
    <Box display="flex" flexDirection="column" style={{ paddingTop: "10px" }}>
      <Box display="flex" alignItems="center">
        <Typography variant="h3" style={{ marginRight: "20px" }}>
          Weekly Summary - {user.account.name}
        </Typography>
        <Button onClick={() => addDays(-7)} variant="contained">
          Prev Week
        </Button>
        <Typography style={{ margin: "10px" }}>
          w/b {selectedDate.format("DD/MM/YYYY")}
        </Typography>
        <Button onClick={() => addDays(7)} variant="contained">
          Next Week
        </Button>
      </Box>

      <Divider my={2} />
      <Card m={6}>
        <Paper>
          {loading && <h2 style={{ margin: "10px" }}>Loading ...</h2>}
          {!loading && weeklySummary && weeklySummary.length === 0 && (
            <h2 style={{ margin: "10px" }}>No entries</h2>
          )}
          {!loading && weeklySummary && weeklySummary.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Account</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Travel (mins)</TableCell>
                  <TableCell align="center">
                    Attendance
                    <br />
                    (mins)
                  </TableCell>
                  <TableCell align="center">
                    Write Up
                    <br />
                    (mins)
                  </TableCell>
                  <TableCell align="center">ActivityType</TableCell>
                  <TableCell align="center">
                    Activity time
                    <br />
                    (mins)
                  </TableCell>
                  <TableCell align="center">
                    Total time
                    <br />
                    (mins)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {weeklySummary.map((entry) => (
                  <TableRow key={entry.id}>
                    <TableCell>{entry.accountName}</TableCell>
                    <TableCell align="center">
                      {moment(entry.date).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell align="center">{entry.travelMins}</TableCell>
                    <TableCell align="center">{entry.attendanceMins}</TableCell>
                    <TableCell align="center">{entry.writeUpMins}</TableCell>
                    <TableCell align="center">{entry.activityType}</TableCell>
                    <TableCell align="center">{entry.activityMins}</TableCell>
                    <TableCell align="center">
                      {entry.travelMins +
                        entry.attendanceMins +
                        entry.writeUpMins +
                        entry.activityMins}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell>TOTAL</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    {weeklySummary.reduce(
                      (prev, next) => prev + next.travelMins,
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {weeklySummary.reduce(
                      (prev, next) => prev + next.attendanceMins,
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {weeklySummary.reduce(
                      (prev, next) => prev + next.writeUpMins,
                      0
                    )}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    {weeklySummary.reduce(
                      (prev, next) => prev + next.activityMins,
                      0
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {weeklySummary.reduce(
                      (prev, next) =>
                        prev +
                        next.travelMins +
                        next.attendanceMins +
                        next.writeUpMins +
                        next.activityMins,
                      0
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          )}
        </Paper>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state) => ({ user: state.userReducer.user });

export default connect(mapStateToProps)(WeeklySummary);
