import { format } from "date-fns";

const formatDate = (dateString) => {
  try {
    return format(new Date(dateString), "dd/MM/yyyy");
  } catch (error) {
    return "Invalid Date";
  }
};

export { formatDate };
