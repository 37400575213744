import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import timesheetService from "../../services/timesheetService";

const useVisitSummary = () =>
  useQuery([queryKeys.visitSummary], () =>
    timesheetService.fetchVisitSummary()
  );

export default useVisitSummary;
