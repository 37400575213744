import React, { useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    Box,
    Typography,
    Card,
    CardContent
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import stripeLogo from "../images/stripe.svg";
import FacilitiesImage from "../images/Facilities.jpg";
import BusinessImage from "../images/BusinessOwner.png";
import HSImage from "../images/Health&Safety.jpg";
import HRImage from "../images/HRProfessional.jpg";

import {
    DescriptionOutlined as PaperIcon,
    Apps as AppsIcon,
    PeopleAlt as UsersIcon
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    box: {
        border: '3px solid #ededed',
        height: '500px'
    },
    shaded: {
        background: '#ededed'
    },
    lower: {
        padding: '20px 40px'
    },
    choiceBox: {
        "&.selected": {
            border: '2px solid #12054d'
        },
        border: '2px solid #f7f9fc',
        height: '150px',
        width: '150px',
        padding: '12px',
        background: '#ededed',
        textAlign: 'center',
        margin: '20px'
    }
}));

const roles = [
    { name: "Business Owner / Manager", image: BusinessImage, color:"#f2deff" },
    { name: "HR Professional", image: HRImage, color: "#ffebe5" },
    { name: "Health & Safety Professional", image: HSImage, color: "#defaf7" },
    { name: "Facilities Manager", image: FacilitiesImage, color: "#f2deff" }
];
const apps = [
    { color: '#ff6961', name: "No, we track everything in spreadsheets." },
    { color: '#C1E1C1', name: "Yes, we already use a HR Software Package" }
]
const compliances = [
    { color: '#ff6961', name: "No, it's a while since we reviewed our contracts and policies" },
    { color: '#C1E1C1', name: "Yes, we're happy our contrats and policies are compliant." }
];

const SalesProto = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [stage, setStage] = useState(1);
    const [role, setRole] = useState(null);
    const [app, setApp] = useState(null);
    const [compliance, setCompliance] = useState(null);

    const classes = useStyles();

    const FixedFeeInfo = () => (
        <Grid item xs={12} sm={6} md={4} lg={3} className={`${classes.box} ${classes.shaded}`}>
            <Typography style={{padding:'20px'}} variant="h4">Flexible, fixed fee Employment Law & HR Support</Typography>
            <div className={classes.lower}>
                <Box style={{ margin: '20px 0' }}>From</Box>
                <Box style={{ marginBottom: '50px' }}>&pound;<span style={{ fontSize: '2em' }}>120/</span>month + VAT</Box>
                <Box fontSize={16}>Contracts and Handbooks</Box>
                <Box fontSize={16}>Drafting Letters and documents</Box>
                <Box fontSize={16}>E-learning</Box>
                <Box fontSize={16}>HR Software</Box>
                <Box fontSize={16}>Legal Expenses Insurance</Box>
            </div>
        </Grid>
    );
    return (
        <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
            style={{ minHeight: 'calc(100vh-150px)' }}
        >
            {
                stage === 1 &&
                <>
                    <Grid item xs={12} sm={6} md={4} lg={3} className={classes.box}>
                        <Typography style={{ padding: '20px' }} variant="h2">Sign Up</Typography>
                        <div className={classes.lower}>
                            <Typography variant="h6">Already have an account? <a href="/signin">Sign in</a></Typography>
                            <TextField
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                label="Email Address"
                                m={2}
                                type="email"
                                name="email"
                                fullWidth
                                variant="outlined"
                                style={{ marginTop: '20px' }}
                            />
                            <TextField
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                label="Password"
                                type="password"
                                fullWidth
                                m={2}
                                variant="outlined"
                                style={{ marginTop: '20px' }}
                            />
                            <Button onClick={()=>setStage(2)} disabled={username === "" || password === ""} variant="contained" color="primary" fullWidth style={{ marginTop: '40px', marginBottom: '40px' }}>Sign Up</Button>
                            <Box>By selecting Sign Up, you agree to our Terms and read and acknowledge our Data Privacy Statement.</Box>
                        </div>
                    </Grid>
                    <FixedFeeInfo/>
                </>
            }            
            {
                stage === 2 &&
                <>
                    <Grid item xs={12} sm={6} md={4} lg={3} className={classes.box}>
                        <Box style={{ color: 'white', background: '#12054d', padding: '40px' }}><Typography variant="h4">Thanks, Your myWorknest account is ready</Typography></Box>
                        <div className={classes.lower}>
                            <Box fontSize={16} mt={5}>Your UserId is</Box>
                            <Box fontSize={16} mt={5}>{username}</Box>
                            <Button onClick={() => setStage(3)} variant="contained" color="primary" fullWidth style={{ marginTop: '40px', marginBottom: '40px' }}>Open myWorknest</Button>
                        </div>
                    </Grid>
                    <FixedFeeInfo />
                </>
            }
            {
                stage === 3 &&
                <Grid item xs={12} sm={12} md={8} lg={6} className={classes.box} style={{ padding: '20px' }}>
                    <Typography variant="h2">1.  Get set up in a few simple steps</Typography>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" style={{marginTop:'20px'}}>
                        <Box textAlign="center">
                            <PaperIcon style={{fontSize:'5em'}}/>
                            <Typography>Add your business info</Typography>
                        </Box>
                        <Box textAlign="center">
                            <AppsIcon style={{ fontSize: '5em' }}/>
                            <Typography>Activate your WorkNest features</Typography>
                        </Box>
                        <Box textAlign="center">
                            <img src={stripeLogo} style={{height:'70px'}} alt="stripe logo"/>
                            <Typography>Add your payment details</Typography>
                        </Box>
                    </Box>
                    <Box fontSize={16} mt={5}>Things you'll need during set up</Box>
                    <Box fontSize={16} mt={5}>Your company registration number</Box>
                    <Box fontSize={16} mt={5}>Payment Details</Box>
                    <Box textAlign='center'><Button onClick={() => setStage(4)} variant="contained" color="primary" >Start Set Up</Button></Box>
                </Grid>
            }
            {
                stage === 4 &&
                <Grid item xs={12} sm={12} md={8} lg={6} className={classes.box} style={{ padding: '20px' }}>
                    <Typography variant="h2">2. Tell us about your business</Typography>
                    <Box display="flex" flexDirection="column" style={{ padding: '20px' }}>
                        <Typography>Everyone needs something a little different from WorkNest.  Let's get to know what you need so we can tailor things to fit you.  You can change your info anytime in settings.</Typography>
                        <TextField
                            label="Legal Business Name"
                            m={2}
                            type="text"
                            variant="outlined"
                            style={{ marginTop: '20px' }}
                        />
                        <TextField
                            label="Hom many employees in your organisation"
                            m={2}
                            type="number"
                            variant="outlined"
                            style={{ marginTop: '20px' }}
                        />
                        <TextField
                            label="Company Registration Number"
                            m={2}
                            type="text"
                            variant="outlined"
                            style={{ marginTop: '20px' }}
                        />
                    </Box>           
                    <Box textAlign='center'><Button onClick={() => setStage(5)} variant="contained" color="primary" >Next</Button></Box>
                </Grid>
            }
            {
                stage === 5 &&
                <Grid item xs={12} sm={12} md={8} lg={6} className={classes.box} style={{ padding: '20px' }}>
                    <Typography variant="h2">3. What's you role at the business</Typography>
                    <Typography>We'll use this info to help personalise your WorkNest setup.  You can always change your settings later in settings.</Typography>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ padding: '20px' }}>
                        {roles.map(r =>
                            <Box style={{padding:0}} display="flex" flexDirection="column" key={r.name} onClick={() => setRole(r)} className={`${classes.choiceBox} ${(r === role ? "selected" : "")}`}>
                                <Box style={{ background: r.color, minHeight: '65px' }} display="flex" alignItems="center" justifyContent="center">{r.name}</Box>
                                <Box style={{ overflow: 'hidden' }}><img src={r.image} style={{ width: '100%' }} alt={r.name} /></Box>
                            </Box>
                        )}
                    </Box>
                    <Box textAlign='center'><Button disabled={role===null} onClick={() => setStage(6)} variant="contained" color="primary" >Next</Button></Box>
                </Grid>
            }
            {
                stage === 6 &&
                <Grid item xs={12} sm={12} md={8} lg={6} className={classes.box} style={{ padding: '20px' }}>
                    <Typography variant="h2">4. Do you have a HR Software application right now?</Typography>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ padding: '20px' }}>
                        <Box></Box>
                        {apps.map(a => <Box style={{ background: a.color }} display="flex" alignItems="center" justifyContent="center" key={a.name} onClick={() => setApp(a)} className={`${classes.choiceBox} ${(a === app ? "selected" : "")}`}>{a.name}</Box>)}
                        <Box></Box>
                    </Box>
                    <Box textAlign='center'><Button disabled={app === null} onClick={() => setStage(7)} variant="contained" color="primary" >Next</Button></Box>
                   
                </Grid>
            }
            {
                stage === 7 &&
                <Grid item xs={12} sm={12} md={8} lg={6} className={classes.box} style={{ padding: '20px' }}>
                    <Typography variant="h2">5. Do all your employees have standard, compliant contracts?</Typography>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ padding: '20px' }}>
                        <Box></Box>
                        {compliances.map(c => <Box style={{ background: c.color }} display="flex" alignItems="center" justifyContent="center" key={c.name} onClick={() => setCompliance(c)} className={`${classes.choiceBox} ${(c === compliance ? "selected" : "")}`}>{c.name}</Box>)}
                        <Box></Box>
                    </Box>
                    <Box textAlign='center'><Button disabled={compliance === null} onClick={() => setStage(8)} variant="contained" color="primary" >Next</Button></Box>
                </Grid>
            }
            {
                stage === 8 &&
                <Grid item xs={12} sm={12} md={8} lg={6} style={{ padding: '20px' }}>
                    <Typography variant="h1">Your Worknest Package</Typography>
                    <Grid container style={{ padding: '16px' }}>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={2}><Box display="flex" flexDirection="column" alignItems="center"><UsersIcon style={{ fontSize: '2em' }}/><span style={{fontSize:'0.6em'}}>Users</span></Box></Grid>
                        <Grid item xs={3}><Box textAlign="center" style={{ fontSize: '2em' }}>&pound;</Box></Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Employment Law Core Package</Typography>
                            <Grid container>
                                <Grid item xs={5}>At the core of our support for you organisation is our employee relationsadvisory service, delivered by our experts.</Grid>
                                <Grid item xs={2} style={{ fontSize: '2em' }}><Box textAlign="center">100</Box></Grid>
                                <Grid item xs={3}><Box textAlign="center">&pound;<span style={{ fontSize: '2em' }}>120/</span>month + VAT</Box></Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>
                        </CardContent>                       
                    </Card>
                    <Typography style={{ marginTop: '20px' }}>Optional Packages</Typography>
                    <Card style={{marginTop:'10px'}}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Contracts and Handbooks Review</Typography>
                            <Grid container>
                                <Grid item xs={5}>Compliant contracts and handbooks are the cornerstone of good HR management.  Our experts will carefully review and refine these essential documents to give you the confidence that hey are up to date, fot for purpose and offer maximum flexibility.</Grid>
                                <Grid item xs={2} style={{ fontSize: '2em' }}><Box textAlign="center"></Box></Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={2}><Box display="flex" alignItems="flex-end" alignContent="flex-end"><Button style={{background:'#e20f00', color:'white'}} variant="contained" size="small">Remove</Button></Box></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card style={{ marginTop: '10px' }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>HR Software</Typography>
                            <Grid container>
                                <Grid item xs={5}>PeopleNest sets a new benchmark for HR delivery, bringing together the HR fundamentals of policy, process and - most importantly - people in a single environment.</Grid>
                                <Grid item xs={2} style={{ fontSize: '2em' }}><Box textAlign="center">100</Box></Grid>
                                <Grid item xs={3}><Box textAlign="center">&pound;<span style={{ fontSize: '2em' }}>100/</span>month + VAT</Box></Grid>
                                <Grid item xs={2}></Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Grid container style={{padding: '16px'}}>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={2} style={{ fontSize: '2em' }}></Grid>
                        <Grid item xs={3}><Box textAlign="center">&pound;<span style={{ fontSize: '2em' }}>220/</span>month + VAT</Box></Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>                  
                </Grid>
                
                
            }
        </Grid>
    );
}


export default SalesProto;
