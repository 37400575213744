import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  Paper,
  makeStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { FolderOutlined } from "@material-ui/icons";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import SortableTree from "react-sortable-tree";
import useAccountFiles from "../../hooks/queries/useAccountFiles";
import useAddAccountFile from "../../hooks/useAddAccountFile";
import useDeleteAccountFile from "../../hooks/useDeleteAccountFile";
import useUpdateAccountFile from "../../hooks/useUpdateAccountFile";
import { setSnackAction } from "../../store/actions/snackActions";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "0 auto",
    padding: theme.spacing(6),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "50%",
    },
  },
  attachNewFileInput: {
    display: "none",
  },
  attachFileButton: {
    marginLeft: theme.spacing(4),
  },
  removeFileIcon: {
    cursor: "pointer",
  },
  padTop: {
    paddingTop: theme.spacing(2),
  },
  treeFolderTitle: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-1),
  },
  treeTitle: {
    border: 0,
    outline: 0,
    width: 300,
  },
  subTitleText: {
    fontWeight: 700,
  },
  treeSubtitle: {
    border: 0,
    outline: 0,
    width: 200,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  treeSubtitleText: {
    fontSize: 12,
  },
}));

const initialTreeData = [
  {
    title: "General Risk Assessments",
    isPermanent: true,
    children: [],
  },
  {
    title: "Fire Risk Assessments",
    isPermanent: true,
    children: [],
  },
  {
    title: "Manual and Handbook",
    isPermanent: true,
    children: [],
  },
  { title: "Miscellaneous", isPermanent: true, children: [] },
];

const fileTypeHS = 1;
const documentType = 0;

const DocumentsTable = ({ account }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accountId = account?.accountId;
  const { data, isLoading, isError, error } = useAccountFiles(
    accountId,
    fileTypeHS,
    documentType
  );
  const addAccountFile = useAddAccountFile();
  const deleteAccountFile = useDeleteAccountFile();
  const updateAccountFile = useUpdateAccountFile();

  const [fileToRemove, setFileToRemove] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [treeData, setTreeData] = useState(initialTreeData);

  const handleUploadAccountFile = async (newFile) => {
    if (newFile.target.files[0]) {
      return Array.from(newFile.target.files).forEach((file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("accountId", accountId);
        formData.append("accountFileTypeId", fileTypeHS);

        try {
          addAccountFile.mutate(
            { formData, accountId, fileTypeHS, documentType },
            {
              onError: (e) =>
                dispatch(
                  setSnackAction(
                    e?.message || "There was an error adding the File",
                    "error"
                  )
                ),
              onSuccess: () =>
                dispatch(setSnackAction("File added", "success")),
            }
          );
        } catch (error) {
          console.error(error);
        }
      });
    }
  };

  const openDeleteFilePopup = (e, form) => {
    e.stopPropagation();
    setFileToRemove(form);
    setIsDeleteOpen(true);
  };

  const handleRemoveFile = async (e) => {
      e.stopPropagation();

      const formData = new FormData();
      formData.append("fileName", fileToRemove.uri);

    deleteAccountFile.mutate(
      { formData, documentType },
      {
        onError: (e) =>
          dispatch(
            setSnackAction(
              e?.message || "There was an error removing File",
              "error"
            )
          ),
        onSuccess: () => dispatch(setSnackAction("File removed", "success")),
      }
    );
    setIsDeleteOpen(false);
  };

    const handleUpdateFile = async (node) => {
        const getPrefix = (name) => {
            const prefix = name.split("Safety")[0] + "Safety";

            return !node.nextParentNode
                ? `${prefix}/${node.node.title}`
                : `${prefix}/${node.nextParentNode.title}/${node.node.title}`;
        };

        const newFileName = getPrefix(node.node.uri);

        if (node.node.uri !== newFileName) {
            const formData = new FormData();
            formData.append("fileName", node.node.uri);
            formData.append("newFileName", newFileName);
            updateAccountFile.mutate(
                {
                    formData, documentType
                },
                {
                    onError: (e) =>
                        dispatch(
                            setSnackAction(
                                e?.message || "There was an error updating File",
                                "error"
                            )
                        ),
                    onSuccess: () => dispatch(setSnackAction("File updated", "success")),
                }
            );
        };
    }

  useEffect(() => {
    if (data && data.length > 0) {
      // if folder exists then remove the empty one
      const filterDuplicateFolders = initialTreeData.filter(
        (initial) => !data.find((current) => current.title === initial.title)
      );

      setTreeData(data.concat(filterDuplicateFolders));
    } else {
      setTreeData(initialTreeData);
    }
  }, [data]);

  const treeContent = () => {
    if (isLoading)
      return (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={48} />
        </Box>
      );

    if (isError)
      return (
        <Box display="flex" justifyContent="center">
          <Typography>
            {error?.message || "Could not fetch files for account"}
          </Typography>
        </Box>
      );

    return (
      <SortableTree
        treeData={treeData}
        onChange={(treeData) => setTreeData(treeData)}
        onMoveNode={(node) => handleUpdateFile(node)}
        canDrag={(node) => !node.node?.isPermanent}
        canNodeHaveChildren={(node) => node.isPermanent}
        generateNodeProps={({ node, path }) => ({
          title: (
            <Box display="flex" alignItems="center">
              {node.isPermanent ? (
                <>
                  <Box>
                    <FolderOutlined fontSize="small" />
                  </Box>

                  <Box className={classes.treeFolderTitle} flexGrow={1}>
                    <Typography className={classes.subTitleText}>
                      {node.title}
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box className={classes.treeFolderTitle} flexGrow={1}>
                  <Typography>{node.title}</Typography>
                </Box>
              )}
            </Box>
          ),
          subtitle: node.isPermanent ? null : (
            <Box className={classes.treeSubtitle}>
              <Typography className={classes.treeSubtitleText}>
                {"Date Added : " + node.subtitle}
              </Typography>
            </Box>
          ),
          buttons: node.isPermanent
            ? []
            : [
                <Box display="flex" alignItems="flex-start">
                  <DeleteOutlineOutlinedIcon
                    color="error"
                    className={classes.removeFileIcon}
                    onClick={(e) => openDeleteFilePopup(e, node)}
                  >
                    Remove
                  </DeleteOutlineOutlinedIcon>
                </Box>,
              ],
        })}
      />
    );
  };

  return (
    <Paper className={classes.card}>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.padTop}
      >
        <Typography variant="h4" pt={1} color="primary">
          {account?.accountName}
        </Typography>
        <Button
          className={classes.attachFileButton}
          variant="outlined"
          color="primary"
          component="label"
        >
          <input
            type="file"
            variant="outlined"
            color="primary"
            className={classes.attachNewFileInput}
            onChange={handleUploadAccountFile}
            multiple
          />
          Add document
        </Button>
      </Box>

      <Box height="70vh" p={2} mt={2}>
        {treeContent()}
      </Box>

      <Dialog
        open={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Removal</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you wish to remove the file
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteOpen(false)} color="default">
            Cancel
          </Button>
          <Button onClick={handleRemoveFile} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default DocumentsTable;
