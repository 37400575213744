import { useMutation, useQueryClient } from "react-query";
import queryKeys from "../constants/queryKeys";
import documentService from "../services/documentService";

const useUpdateAccountFile = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ formData, documentType }) =>
            documentService.updateAccountFile(formData, documentType),
        {
            onSuccess: () => queryClient.invalidateQueries(queryKeys.accountFiles),
        }
    );
};

export default useUpdateAccountFile;
