import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import {
    Typography,
    Box,
    Button
} from "@material-ui/core";
import {
    Delete as DeleteIcon,
    CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import styled from "styled-components";
import { grey } from "@material-ui/core/colors";
const IconButton = styled(Button)`
  padding: 2px;
  margin-top: -2px;
  margin-left: 2px;
  min-width: 0;
  color: ${grey[700]};
`;

const FileUpload = ({ question, updateAnswer }) => {

    const files = useMemo(() => question.answer || [], [question.answer]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            let newFiles = acceptedFiles.filter(
                (f) => files.filter((e) => e.path === f.path).length === 0
            );

            updateAnswer(question.questionId, [...files, ...newFiles]);
            
            console.log("e", files, acceptedFiles);
        },
        [files, question.questionId, updateAnswer]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const removeFile = (file) => {
        updateAnswer(question.questionId, files.filter((f) => f !== file));        
    };

    return (
        <section>
            <Box
                display="flex"
                flexDirection="column"
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <Box display="flex" alignItems="center">
                    <CloudUploadIcon style={{ marginRight: "5px" }} />
                    <Typography style={{ cursor: "default" }}>Drag and drop your files here or <strong>click</strong> to browse</Typography>
                </Box>
                <ul>
                    {files.map((file) => (
                        <li key={file.path}>
                            <Box alignItems="center" display="flex">
                                <Box>{file.path} </Box>
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        removeFile(file);
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </li>
                    ))}
                </ul>
            </Box>
        </section>
    );
}

export default FileUpload;
