const accountFiles = "account-files";
const visitAutomationWindow = "visit-automation-window";
const visitSummary = "visit-summary";
const pardotJobTitles = "pardot-job-titles"

const queryKeys = {
  accountFiles,
  visitAutomationWindow,
    visitSummary,
  pardotJobTitles
};

export default queryKeys;
