import { useMutation, useQueryClient } from "react-query";
import queryKeys from "../constants/queryKeys";
import documentService from "../services/documentService";

const useDeleteAccountFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
      ({ formData, documentType }) =>
          documentService.deleteAccountFile(formData, documentType),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.accountFiles),
    }
  );
};

export default useDeleteAccountFile;
