import { Plugin } from '@ckeditor/ckeditor5-core';
import {
    toWidget,
    viewToModelPositionOutsideModelElement,
} from "@ckeditor/ckeditor5-widget/src/utils";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import PlaceholderCommand from "./PlaceholderCommand";
import store from "../store/";

export default class PlaceholderEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add("placeholder", new PlaceholderCommand(this.editor));

        this.editor.editing.mapper.on(
            "viewToModelPosition",
            viewToModelPositionOutsideModelElement(this.editor.model, (viewElement) => viewElement.hasClass("placeholder"))
        );

        this.sections = Object.values(store.getState().configReducer.sections);
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register("placeholder", {
            allowWhere: "$text",
            isInline: true,
            isObject: true,
            allowAttributesOf: "$text",
            allowAttributes: ["placeholder", "value"],
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for("upcast").elementToElement({
            view: {
                name: "span",
                classes: ["placeholder"],
            },
            model: (viewElement, { writer: modelWriter }) => {
                const placeholder = {
                    key: viewElement.getAttribute("data-key"),
                };
                //console.log("upcast key", placeholder);

                const value = viewElement.getAttribute("data-value") || "";

                return modelWriter.createElement("placeholder", { placeholder, value });
            },
        });

        conversion.for("editingDowncast").elementToElement({
            model: {
                name: 'placeholder',
                attributes: ['value']
            },
            view: (modelItem, { writer: viewWriter }) => {
                const widgetElement = createPlaceholderView(modelItem, viewWriter);

                // Enable widget handling on a placeholder element inside the editing view.
                return toWidget(widgetElement, viewWriter);
            }
        });

        conversion.for("dataDowncast").elementToElement({
            model: "placeholder",
            view: (modelItem, { writer }) => createPlaceholderView(modelItem, writer),
        });

        function createPlaceholderView(modelItem, writer) {
            const placeholder = modelItem.getAttribute("placeholder");
            let value = modelItem.getAttribute("value");
            //console.log('downcast', placeholder.key, value);

            // Insert the placeholder name (as a text).
            const innerText = writer.createText(value || ("{" + placeholder.key + "}"));

            const placeholderView = writer.createContainerElement(
                "span",
                {
                    class: "placeholder",
                    "data-key": placeholder.key,
                    "data-value": value || "",
                    style: value && "background-color: inherit;",
                },
                [
                    innerText
                ]
            );           

            return placeholderView;
        }
    }
}
