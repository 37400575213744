import React, { useEffect, useState } from 'react';
import axios from '../plugins/axios';
import Box from "@material-ui/core/Box";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const MeetingPlayer = ({ match }) => {

    const { recordingId } = match.params;

    const [recordingUrl, setRecordingUrl] = useState();

    const [checking, setChecking] = useState(true);

    useEffect(() => {

        const getUrl = async () => {
            let response = await axios.get(`${apiUrl}/teamsrecording/${recordingId}`);
            setRecordingUrl(response.data);
            setChecking(false);
        };

        getUrl();

    }, [recordingId]);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%">
            {checking && "Looking for meeting"}
            {!checking && !recordingUrl && "Meeting not found"}
            {
                recordingUrl &&
                <video controls style={{height: 'calc(100vh - 200px)'}}>
                    <source src={recordingUrl} type="video/mp4" />
                </video>
            }
        </Box>
    );
}

export default MeetingPlayer;
