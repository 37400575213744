import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ragColors from '../../constants/ragColors';
import { format } from 'date-fns'
import { setEditingAction } from '../../store/actions/targetActions';
import { actionCreators } from '../../store/reducers/targetReducer';

import {
    Box,
    TableRow,
    TableCell,
    Link,
    IconButton,
    Menu,
    MenuItem
} from "@material-ui/core";

import {
    ArrowDropDown,
    ArrowDropUp,
    MoreVert
} from "@material-ui/icons";

const TargetTableRow = ({ view, target, completeAction, setEditing }) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const formatValue = (value, format) => {
        if (value == null)
            return null;

        value = value.toLocaleString();

        switch (format) {
            case 2:
                return <span>&pound;{value}</span>;
            case 1:
                return `${value}%`;
            default:
                return value;
        }
    }    

    const trendInfo = t => {
        if (t.actual == null || t.previousValue == null)
            return null;

        let difference = t.actual - t.previousValue;

        if (difference > 0)
            return (
                <Box display="flex" alignItems="center" style={{ color: 'green' }}>
                    <ArrowDropUp />
                    <Box>{formatValue(difference, t.targetType)}</Box>
                </Box>
            );

        if (difference < 0)
            return (
                <Box display="flex" alignItems="center" style={{ color: 'red' }}>
                    <ArrowDropDown />
                    <Box>{formatValue(difference, t.targetType)}</Box>
                </Box>
            );

        return "--";
    }

    const ragCssColor = ragColor => {
        switch (ragColor) {
            case ragColors.GREEN:
                return "green";
            case ragColors.AMBER:
                return "#ffbf00";
            case ragColors.RED:
                return "red";
            default:
                return "transparent";
        }
    }

    const handleMenuClick = (targetId, type) => {
        setAnchorEl(null);
        setEditing(targetId, type);
    }
        
    return (
        <TableRow>
            <TableCell>
                <Box display="flex" alignItems="center" gridGap='10px'>
                    <div style={{ backgroundColor: ragCssColor(target.ragColor), borderRadius: '5px', height: '10px', width: '10px', border: '1px solid #a0a0a0' }}></div>
                    <Box display="flex" flexDirection="column">
                        <Box>{target.description}</Box>
                        <Box style={{ color: '#675e8f', fontSize: '0.8em' }}>{target.owner}</Box>
                    </Box>
                </Box>
            </TableCell>
            <TableCell align="right">{formatValue(target.target, target.targetType)}</TableCell>
            <TableCell align="right">{formatValue(target.previousValue, target.targetType)}</TableCell>
            <TableCell align="right">
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <strong>{formatValue(target.actual, target.targetType)}</strong>
                    {trendInfo(target)}
                </Box>
            </TableCell>
            {
                view === "scorecard" &&
                <React.Fragment>
                        <TableCell>
                            {target.fact && <span><span style={{fontWeight: 500}}>Fact:</span> {target.fact}</span>}
                            {target.cause && <span><br /><span style={{ fontWeight: 500 }}>Cause:</span> {target.cause}</span>}
                            {target.action && <span><br /><span style={{fontWeight: 500}}>Action:</span> {target.action}</span>}
                        </TableCell>
                        <TableCell>
                            {target.previousAction}
                            {target.previousAction &&
                                <Box display="flex" flexDirection="column" style={{ color: '#8c72f2'}}>                                    
                                    {
                                        target.previousActionCompletedOn
                                            ? <span>Completed on {format(new Date(target.previousActionCompletedOn), 'dd/MM/yy')}</span>
                                            : <Link style={{ cursor: 'pointer' }} onClick={() => completeAction(target.targetId)}>Complete Action</Link>
                                    }
                                </Box>
                            }
                        </TableCell>
                        <TableCell><IconButton onClick={e => setAnchorEl(e.currentTarget)}><MoreVert /></IconButton></TableCell>
                </React.Fragment>
            }
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleMenuClick(target.targetId, "details")}>Details</MenuItem>
                <MenuItem onClick={() => handleMenuClick(target.targetId, "limits")}>Targets</MenuItem>
                {target.target !== null && <MenuItem onClick={() => handleMenuClick(target.targetId, "actual")}>Actual</MenuItem>}
            </Menu>
        </TableRow>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setEditing: (targetId, type) => dispatch(setEditingAction(targetId, type))
    }
}

export default connect(null, mapDispatchToProps)(TargetTableRow);