import * as types from '../constants';

const initialState = {
    user: null,
    checkingUserSession: true,
};

export const actionCreators = {
    setUser: user => async (dispatch, getState) => {
        dispatch({ type: types.SET_USER, user });
    },
};

const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === types.SET_USER) {
        if (action.user)
            localStorage.setItem('tokenResponse', JSON.stringify(action.user));
        else localStorage.removeItem('tokenResponse');
        return {
            ...state,
            user: action.user,
        };
    }

    if (action.type === types.SET_CHECKING_USER_SESSION) {
        return {
            ...state,
            checkingUserSession: action.isChecking,
        };
    }

    return state;
};

export default reducer;
