import React, { useState, useEffect } from 'react';
import axios from '../plugins/axios';

import { Configuration, OpenAIApi } from "openai";

import {
    Grid,
    Box,
    Paper,
    CircularProgress,
    TextField,
    Button,
    Container,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    OutlinedInput,
    Table,
    TableBody,
    TableRow,
    TableCell

} from "@material-ui/core";

const configuration = new Configuration({
    apiKey: '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',
});

const openai = new OpenAIApi(configuration);

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const AIPlayground = () => {

    const [processing, setProcessing] = useState(false);
    const [result, setResult] = useState();
    const [models, setModels] = useState([]);
    const [model, setModel] = useState('');
    const [thread, setThread] = useState([
        { role: "system", content: "You are an expert Employment Law Adviser specialising in UK Employment Law." },
        { role: "user", content: "" }
    ]);

    const setContent = (value, index) => {
        setThread(thread.map((m, i) => index === i ? { role: m.role, content: value } : m));
    }

    useEffect(() => {
        const getModels = async () => {
            var response = await axios.get(`${apiUrl}/ai/getmodels`);
            setModels(response.data);
            setModel(response.data[0]?.model);
        }

        getModels();
    }, []);

    const submit = async () => {
        setProcessing(true);

        const chatCompletion = await openai.createChatCompletion({
            model: model,
            messages: thread
        });

        setThread([
            ...thread,
            { role: "assistant", content: chatCompletion.data.choices[0].message.content },
            { role: "user", content: "" }
        ]);
        setResult(chatCompletion.data);

        setProcessing(false);
    };

    return (
        <Container>
            <Box display="flex" flexDirection="column">
                <h4>AI Playground</h4>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper >
                            <Box display="flex" flexDirection="column" p={3}>
                                <h3>Prompt</h3>
                                <FormControl variant="outlined" margin="dense">
                                    <InputLabel shrink>Model</InputLabel>
                                    <Select
                                        value={model}
                                        fullWidth
                                        input={<OutlinedInput notched label="Model" />}
                                        onChange={e => setModel(e.target.value)}
                                    >
                                        {
                                            models.map(m => <MenuItem key={m.model} value={m.model}>{m.name}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                                <Table size="small">
                                    <TableBody>
                                        {thread.map((m, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{m.role}</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        value={m.content}
                                                        variant="outlined"
                                                        margin="dense"
                                                        fullWidth
                                                        multiline
                                                        minRows={2}
                                                        onChange={e => setContent(e.target.value, index)}
                                                        disabled={processing} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {
                                    model &&
                                    <Button variant="contained" disabled={processing} onClick={submit}>Submit</Button>
                                }
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper>
                            <Box display="flex" flexDirection="column" p={3}>
                                <h3>Result</h3>
                                <div style={{ border: '1px solid grey', minHeight: '50px', padding: '10px', overflow: 'auto' }}>
                                    {
                                        processing &&
                                        <div style={{ textAlign: 'center' }} ><CircularProgress /></div>
                                    }
                                    {
                                        !processing &&
                                        <pre>{JSON.stringify(result, null, 2)}</pre>
                                    }
                                </div>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
};


export default AIPlayground;
