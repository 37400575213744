import { Plugin } from '@ckeditor/ckeditor5-core';
import {
    toWidget,
    viewToModelPositionOutsideModelElement
} from '@ckeditor/ckeditor5-widget/src/utils';
import Widget from '@ckeditor/ckeditor5-widget/src/widget';
import SectionHolderCommand from './SectionHolderCommand';
import store from "../store/";

export default class SectionHolderEditing extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        console.log('SectionHolderEditing#init() got called');

        this._defineSchema();
        this._defineConverters();
        this.editor.commands.add('insertSection', new SectionHolderCommand(this.editor));

        this.editor.editing.mapper.on(
            'viewToModelPosition',
            viewToModelPositionOutsideModelElement(this.editor.model, viewElement => viewElement.hasClass('sectionholder'))
        );

        this.sections = Object.values(store.getState().configReducer.sections);
    }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.register('sectionholder', {
            allowWhere: '$block',
            isObject: true,
            allowAttributes: ['section']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for('upcast').elementToElement({
            view: {
                name: 'section',
                classes: ['sectionholder']
            },
            model: (viewElement, { writer }) => {
                const section = {
                    key: viewElement.getAttribute('data-key')
                };
                //console.log('upcast key', section);               

                return writer.createElement('sectionholder', { section });
            }
        });

        conversion.for('editingDowncast').elementToElement({
            model: {
                name: 'sectionholder',
                attributes: ['value']
            },
            view: (modelItem, { writer: viewWriter }) => {
                const widgetElement = createSectionHolderView(modelItem, viewWriter, this.sections, false);
                return toWidget(widgetElement, viewWriter);
            }
        });

        conversion.for('dataDowncast').elementToElement({
            model: 'sectionholder',
            view: (modelItem, { writer: viewWriter }) => createSectionHolderView(modelItem, viewWriter, this.sections, true)
        });


        function createSectionHolderView(modelItem, viewWriter, sections, isData) {
            const section = modelItem.getAttribute('section');

            //const storeSection = sections.find(x => x.key === section.key);
            //console.log('storeSection', storeSection);
            //let value = modelItem.getAttribute('value');
            //console.log('section', section, value);

            const innerText = viewWriter.createText(section.key);

            const sectionHolderView = viewWriter.createContainerElement(
                'section',
                {
                    class: 'sectionholder',
                    'data-key': section.key,
                    style: isData ? "" : "border-radius: 2px; padding: 10px; background-color: #F0F8FF;"
                },
                [
                    innerText
                ]
            );

            return sectionHolderView;

        }
    }
}