import React, { useState } from 'react';
import {
    Box,
    Tab,
    Tabs,
} from "@material-ui/core";
import TabPanel from "../TabPanel";
import Submissions from "./Submissions";
import Hiscox from "./Hiscox";

const Registrations = () => {

    const [currentTab, setCurrentTab] = useState("submissions");  

    return (
        <Box display="flex" flexDirection="column" style={{ paddingTop: '10px' }}>
            <Tabs value={currentTab} onChange={(e, x) => setCurrentTab(x)} style={{ marginBottom: '10px' }}>
                <Tab value="submissions" label="Submissions" />
                <Tab value="hiscox" label="HISCOX" />
            </Tabs>
            <TabPanel value={currentTab} index="submissions" >
                <Submissions  />
            </TabPanel>
            <TabPanel value={currentTab} index="hiscox" >
                <Hiscox />
            </TabPanel>            
        </Box>
    )
};

export default Registrations;
