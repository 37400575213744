import React, { useEffect } from "react";
import { format } from "date-fns";
import { enGB } from 'date-fns/esm/locale';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link,
} from "@material-ui/core";
import { fetchSubmissions } from '../../store/slices/registrationSlice'

const Submissions = () => {  

    let history = useHistory();
    const { submissions, fetchingSubmissions: fetching } = useSelector((state) => state.registrationReducer);
    const dispatch = useDispatch();
   
    useEffect(() => {
        if (submissions.length === 0)
            dispatch(fetchSubmissions());
    }, [dispatch, submissions.length]);   
        
    return (
        <>
            {fetching && <h2 style={{ margin: '10px' }}>Loading ...</h2>}
            {!fetching && submissions.length === 0 && <h2 style={{ margin: '10px' }}>No entries</h2>}
            {!fetching && submissions.length > 0 &&
                <Table size="small">
                    <TableHead>
                        <TableRow style={{ height: '37px' }}>
                            <TableCell>Date</TableCell>     
                            <TableCell>Company</TableCell>
                            <TableCell>Policy Number</TableCell>
                            <TableCell>Contact</TableCell>                                                   
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {submissions.map(s =>
                            <TableRow onClick={() => { history.push(`/registration/${s.id}`); }} key={s.id} style={{ cursor: 'pointer' }}>
                                <TableCell>{format(new Date(s.createdOn), "Pp", {locale: enGB })}</TableCell>
                                <TableCell>{s.companyName}</TableCell>
                                <TableCell>{s.policyNumber}</TableCell>
                                <TableCell>{s.firstName} {s.lastName}</TableCell>                             
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            }
        </>
    )
};

export default Submissions;
