import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card as MuiCard,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  KeyboardArrowDown as WorseIcon,
  KeyboardArrowUp as BetterIcon,
  Remove as SameIcon,
} from "@material-ui/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { formatTime } from "../../helpers/formatTime";
import moment from "moment";
import axios from "../../plugins/axios";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const useStyles = makeStyles((theme) => ({
  weeklyViewContainer: {
    height: "100%",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  filterContainer: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
  },
  filterDropdown: {
    width: 150,
    marginLeft: theme.spacing(5),
  },
  filterButtonNext: {
    marginRight: theme.spacing(3),
  },
  boxStyle: {
    backgroundColor: theme.palette.grey[300],
    display: "flex",
    width: "160px",
    height: "160px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px",
    marginLeft: "10px",
  },
  selectAccountContainer: {
    margin: "0 auto",
    padding: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 400,
    },
  },
  contentContainer: {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    height: 0,
    flexGrow: 1,
  },
  infoContainer: {
    display: "flex",
    backgroundColor: theme.palette.grey[300],
    padding: theme.spacing(2),
    marginLeft: theme.spacing(3),
  },
  perfectScrollbar: {
    height: 0,
    flexGrow: 1,
  },
  table: {
    height: "100%",
    maxHeight: "100%",
  },
  tableCellCenter: {
    border: "1px solid grey",
    backgroundColor: theme.palette.grey[300],
  },
  marginThree: {
    margin: theme.spacing(3),
  },
  betterIcon: {
    fontSize: 40,
    color: "green",
  },
  worseIcon: {
    fontSize: 40,
    color: "red",
  },
}));

const BlankTableCell = styled(TableCell)`
  border: none;
`;

const SumTableCell = styled(TableCell)`
  border: 1px solid #808080;
  background-color: #f5f5f5;
`;

const Card = styled(MuiCard)(spacing);

const getBackgroundColor = (dayStatus) => {
  switch (dayStatus) {
    case "B":
      return "lightgreen";
    case "C":
      return "green";
    case "O":
      return "yellow";
    case "A":
      return "lightblue";
    case "P":
      return "lightsalmon";
    case "H":
      return "lightyellow";
    case "S":
      return "coral";
    case "TotalAvailDays":
    case "Capacity":
    case "TotalLostDays":
    case "Utilisation":
      return "thistle";
    case "WriteUpTime":
    case "TravelTime":
    case "SiteTime":
      return "lightgrey";
    case "T":
      return "lightcyan";
    default:
      return "lightcoral";
  }
};

const key = [
  {
    label: "Free",
    letter: "",
    color: "lightcoral",
  },
  {
    label: "Holiday",
    letter: "H",
    color: "lightyellow",
  },
  {
    label: "Sickness",
    letter: "S",
    color: "coral",
  },
  {
    label: "Booked",
    letter: "B",
    color: "lightgreen",
  },
  {
    label: "Completed",
    letter: "C",
    color: "green",
  },
  {
    label: "Other",
    letter: "O",
    color: "yellow",
  },
  {
    label: "Admin",
    letter: "A",
    color: "lightblue",
  },
  {
    label: "Training",
    letter: "T",
    color: "lightcyan",
  },
  {
    label: "Proposed",
    letter: "P",
    color: "lightsalmon",
  },
];

const getSummaryInfo = (dayInfo) => {
  switch (dayInfo.dayStatus) {
    case "Capacity":
    case "BookedDays":
    case "TotalLostDays":
    case "TotalAvailDays":
      return dayInfo.dayCount;
    case "Utilisation":
      return dayInfo.dayCount + "%";
    case "WriteUpTime":
      return formatTime(dayInfo.writeUpMins);
    case "TravelTime":
      return formatTime(dayInfo.travelTimeMins);
    case "SiteTime":
      return formatTime(dayInfo.actSiteMins);
    default:
      return (
        <Typography variant="h6">
          {dayInfo.bookingCount > 1 || dayInfo.dayStatus === "X"
            ? dayInfo.dayStatus + " (" + dayInfo.bookingCount + ")"
            : dayInfo.dayStatus}
        </Typography>
      );
  }
};

// number of weeks to iterate sum row (4 - 1 for row heading)
const n = 3;

function VisitWeeklyView() {
  const classes = useStyles();
  const { user } = useSelector((state) => state.userReducer);

  const [headers, setHeaders] = useState(null);
  const [calendarData, setCalendarData] = useState(null);
  const [dayInfo, setDayInfo] = useState(null);
  const [dayNonVisitInfo, setDayNonVisitInfo] = useState(null);
  const [travelTimeInfo, setTravelTimeInfo] = useState(null);
  const [siteTimeInfo, setSiteTimeInfo] = useState(null);
  const [writeUpTimeInfo, setWriteUpTimeInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().startOf("isoWeek"));
  const [regionFilter, setRegionFilter] = useState("AllRegions");
  const [timeTypeFilter, setTimeTypeFilter] = useState("AllTime");
  const [dayInfoDialogOpen, setDayInfoDialogOpen] = useState(false);
  const [consultantWeeklyTotals, setConsultantWeeklyTotals] = useState(null);
  const [regions, setRegions] = useState(null);

  const handleCloseDayInfoDialog = () => {
    setDayInfoDialogOpen(false);
  };

  const handleTimeTypeChange = (e) => {
    setTimeTypeFilter(e.target.value);
  };

  const handleRegionFilterChange = (e) => {
    setRegionFilter(e.target.value);
  };

  const getVisitCalendar = useCallback(async () => {
    if (!user) return;

    try {
      setLoading(true);
      var response = await axios.get(
        `${apiUrl}/weeklyvisitsummary/${selectedDate.format(
          "YYYY-MM-DD"
        )}/${regionFilter}/${timeTypeFilter}`
      );

      if (response.data) {
        setCalendarData(response.data);
        setHeaders(
          response.data.regions[0]?.consultants[0]?.data.map(
            (data) => data.date
          )
        );
        !regions && setRegions(response.data.regions);

        let totals = {
          1: {
            writeUpMins: 0,
            travelTimeMins: 0,
            actSiteMins: 0,
          },
          2: {
            writeUpMins: 0,
            travelTimeMins: 0,
            actSiteMins: 0,
          },
          3: {
            writeUpMins: 0,
            travelTimeMins: 0,
            actSiteMins: 0,
          },
          4: {
            writeUpMins: 0,
            travelTimeMins: 0,
            actSiteMins: 0,
          },
        };

        const consultants = response.data.regions[0]?.consultants || [];

        consultants.forEach((c) => {
          let week = 1;
          c.data.forEach((d) => {
            if (d.date === "Wk TT")
              totals[week].travelTimeMins += d.travelTimeMins || 0;

            if (d.date === "Wk ST")
              totals[week].actSiteMins += d.actSiteMins || 0;

            if (d.date === "Wk WUT") {
              totals[week].writeUpMins += d.writeUpMins || 0;
              week++;
            }
          });
        });

        setConsultantWeeklyTotals(totals);
      }
    } finally {
      setLoading(false);
    }
  }, [user, selectedDate, regionFilter, timeTypeFilter]);

  useEffect(() => {
    getVisitCalendar();
  }, [user, getVisitCalendar, selectedDate]);

  const addDays = (days) => {
    setSelectedDate(moment(selectedDate).add(days, "days"));
  };

  const handleClick = async (dayInfo) => {
    setDayNonVisitInfo(null);
    setTravelTimeInfo(null);
    setSiteTimeInfo(null);
    setWriteUpTimeInfo(null);

    let response;

    if (
      dayInfo.dayStatus === "B" ||
      dayInfo.dayStatus === "C" ||
      dayInfo.dayStatus === "O" ||
      dayInfo.dayStatus === "P"
    ) {
      response = await axios.get(
        `${apiUrl}/getvisitsfordaterangebyconsultant/${moment(
          dayInfo.dateR
        ).format("YYYY-MM-DD")}/${moment(dayInfo.dateR).format("YYYY-MM-DD")}/${
          dayInfo.consultantEmail
        }`
      );

      setDayInfo(response);
      setDayInfoDialogOpen(true);
    } else if (dayInfo.dayStatus === "SiteTime" && dayInfo.actSiteMins > 0) {
      response = await axios.get(
        `${apiUrl}/getsitetimefordaterangebyconsultant/${dayInfo.yearNr}/${dayInfo.weekNr}/${dayInfo.consultantEmail}`
      );

      setSiteTimeInfo(response);
      setDayInfo(null);
      setDayInfoDialogOpen(true);
    } else if (
      dayInfo.dayStatus === "TravelTime" &&
      dayInfo.travelTimeMins > 0
    ) {
      response = await axios.get(
        `${apiUrl}/getsitetimefordaterangebyconsultant/${dayInfo.yearNr}/${dayInfo.weekNr}/${dayInfo.consultantEmail}`
      );

      setTravelTimeInfo(response);
      setDayInfo(null);
      setDayInfoDialogOpen(true);
    } else if (dayInfo.dayStatus === "WriteUpTime" && dayInfo.writeUpMins > 0) {
      response = await axios.get(
        `${apiUrl}/getsitetimefordaterangebyconsultant/${dayInfo.yearNr}/${dayInfo.weekNr}/${dayInfo.consultantEmail}`
      );

      setWriteUpTimeInfo(response);
      setDayInfo(null);
      setDayInfoDialogOpen(true);
    } else if (dayInfo.comment) {
      setDayNonVisitInfo(dayInfo.comment);
      setDayInfo(null);
      setDayInfoDialogOpen(true);
    }
  };

  return (
    <Card className={classes.weeklyViewContainer}>
      <div className={classes.filterContainer}>
        <Button onClick={() => addDays(-7)} variant="contained">
          Prev Week
        </Button>
        <Typography className={classes.marginThree}>
          w/b {selectedDate.format("DD/MM/YYYY")}
        </Typography>
        <Button
          className={classes.filterButtonNext}
          onClick={() => addDays(7)}
          variant="contained"
        >
          Next Week
        </Button>
        <FormControl className={classes.filterDropdown}>
          <InputLabel>Region</InputLabel>
          <Select value={regionFilter} onChange={handleRegionFilterChange}>
            <MenuItem value="AllRegions">All Regions</MenuItem>
            {regions?.map((region) => (
              <MenuItem
                key={region.regionName ?? "Unknown"}
                value={region.regionName ?? "Unknown"}
              >
                {region.regionName ?? "Not Set"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.filterDropdown}>
          <InputLabel>Time Type</InputLabel>
          <Select value={timeTypeFilter} onChange={handleTimeTypeChange}>
            <MenuItem value="AllTime">All Time</MenuItem>
            <MenuItem value="SiteTime">Site Time</MenuItem>
            <MenuItem value="RemoteTime">Remote Time</MenuItem>
            <MenuItem value="UnknownTime">Unknown Time</MenuItem>
          </Select>
        </FormControl>
      </div>
      {loading && (
        <Typography variant="h2" className={classes.marginThree}>
          Loading ......
        </Typography>
      )}
      {headers && (
        <CardContent className={classes.contentContainer}>
          <Box display="flex" alignItems="flex-end">
            <Box className={classes.infoContainer}>
              {key.map((k, i) => (
                <Box
                  key={i}
                  index={i}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={3}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ backgroundColor: k.color }}
                    height="60px"
                    width="50px"
                  >
                    <div>{k.letter}</div>
                  </Box>
                  <Box textAlign="center">{k.label}</Box>
                </Box>
              ))}
            </Box>
            <Box display="flex" justifyContent="flex-end" pl={2}>
              <div className={classes.boxStyle}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={3}
                >
                  <Box textAlign="center" height="40px">
                    Total Booked Visits
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                    height="60px"
                    width="60px"
                  >
                    <div>
                      <Typography variant="h1">
                        {calendarData && calendarData.bookingCount}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </div>
              <div className={classes.boxStyle}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={3}
                >
                  <Box textAlign="center" height="40px">
                    Utilisation %
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                    height="60px"
                    width="60px"
                  >
                    <div>
                      <Typography variant="h1">
                        {calendarData && calendarData.utilisation}%
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </div>
              <div className={classes.boxStyle}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={3}
                >
                  <Box textAlign="center" height="40px">
                    Total Available Days
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                    height="60px"
                    width="60px"
                  >
                    <div>
                      <Typography variant="h1">
                        {calendarData && calendarData.totalAvailableDays}
                      </Typography>
                    </div>
                  </Box>
                </Box>
              </div>
              <div className={classes.boxStyle}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={3}
                >
                  <Box textAlign="center" height="40px">
                    Highest Bookings by Consultant
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="40px"
                    width="40px"
                  >
                    <Typography variant="h1">
                      {calendarData && calendarData.highestBookingCount}
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    {calendarData &&
                      calendarData.highestBookingPerson.replace("@", " @")}
                  </Box>
                </Box>
              </div>
              <div className={classes.boxStyle}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={3}
                >
                  <Box textAlign="center" height="40px">
                    Lowest Bookings by Consultant
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="30px"
                    width="30px"
                  >
                    <Typography variant="h1">
                      {calendarData && calendarData.lowestBookingCount}
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    {calendarData &&
                      calendarData.lowestBookingPerson.replace("@", " @")}
                  </Box>
                </Box>
              </div>
              <div className={classes.boxStyle}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  mx={3}
                >
                  <Box textAlign="center" height="40px">
                    Average Number of Bookings
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50px"
                    width="50px"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="50px"
                      width="50px"
                    >
                      {calendarData && calendarData.averageBookings && (
                        <Typography variant="h1">
                          {calendarData.averageBookings}
                        </Typography>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="50px"
                      width="50px"
                    >
                      {calendarData &&
                      calendarData.averageBookings &&
                      calendarData.averageBookings >
                        calendarData.averageBookingsPrevPeriod ? (
                        <BetterIcon className={classes.betterIcon} />
                      ) : calendarData.averageBookings <
                        calendarData.averageBookingsPrevPeriod ? (
                        <WorseIcon className={classes.worseIcon} />
                      ) : (
                        <SameIcon />
                      )}
                    </Box>
                  </Box>
                  <Box textAlign="center">Rolling Average</Box>
                  <Box textAlign="center">
                    (Prev Mth -{" "}
                    {calendarData && calendarData.averageBookingsPrevPeriod})
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
          <PerfectScrollbar className={classes.perfectScrollbar}>
            <Table stickyHeader className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Consultant</TableCell>
                  <TableCell>Capacity</TableCell>
                  {headers.map((header, i) => (
                    <TableCell key={i} align="center">
                      {header.toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {calendarData.regions.map((reg, index) =>
                  reg.consultants.map((emp, indexer) => (
                    <TableRow key={indexer}>
                      <TableCell align="left">
                        ({reg.regionName ? reg.regionName.substring(0, 1) : "U"}
                        ) {emp.name}{" "}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellCenter}
                        align="center"
                      >
                        {emp.jobTitle?.toLowerCase().includes("principal")
                          ? 10
                          : emp.jobTitle?.toLowerCase().includes("regional")
                          ? 4
                          : emp.fte === 0.8
                          ? 12
                          : emp.fte === 1.0
                          ? 15
                          : "N/A"}
                      </TableCell>
                      {headers.map((header, index) =>
                        emp.data ? (
                          <TableCell
                            key={index}
                            style={{
                              cursor: emp.data[index].dayStatus && "pointer",
                              borderRight:
                                emp.data[index].score === "Sum" ? "3px" : "1px",
                              borderRightStyle: "solid",
                              borderRightColor: "grey",
                              borderLeft: "1px",
                              borderLeftStyle: "solid",
                              borderLeftColor: "grey",
                              borderBottom: "1px",
                              borderBottomStyle: "solid",
                              borderBottomColor: "grey",
                              paddingBottom: 2,
                              paddingTop: 2,
                              paddingLeft: 2,
                              paddingRight: 2,
                              backgroundColor: getBackgroundColor(
                                emp.data[index].dayStatus
                              ),
                            }}
                            align="center"
                            onClick={() =>
                              emp.data[index].dayStatus &&
                              handleClick(emp.data[index])
                            }
                          >
                            {getSummaryInfo(emp.data[index])}
                          </TableCell>
                        ) : (
                          <TableCell>X</TableCell>
                        )
                      )}
                    </TableRow>
                  ))
                )}
                {consultantWeeklyTotals !== null && (
                  <TableRow>
                    <BlankTableCell colSpan={7}></BlankTableCell>
                    <SumTableCell>
                      {formatTime(consultantWeeklyTotals[1].travelTimeMins)}
                    </SumTableCell>
                    <SumTableCell>
                      {formatTime(consultantWeeklyTotals[1].actSiteMins)}
                    </SumTableCell>
                    <SumTableCell>
                      {formatTime(consultantWeeklyTotals[1].writeUpMins)}
                    </SumTableCell>
                    {[...Array(n)].map((e, i) => (
                      <React.Fragment key={i}>
                        <BlankTableCell colSpan={5}></BlankTableCell>
                        <SumTableCell>
                          {formatTime(
                            consultantWeeklyTotals[i + 2].travelTimeMins
                          )}
                        </SumTableCell>
                        <SumTableCell>
                          {formatTime(
                            consultantWeeklyTotals[i + 2].actSiteMins
                          )}
                        </SumTableCell>
                        <SumTableCell>
                          {formatTime(
                            consultantWeeklyTotals[i + 2].writeUpMins
                          )}
                        </SumTableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          <Dialog
            open={dayInfoDialogOpen}
            onClose={handleCloseDayInfoDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {dayInfo
                ? "Visit Information"
                : writeUpTimeInfo
                ? "Write-Up Time Breakdown"
                : travelTimeInfo
                ? "Travel Time Breakdown"
                : siteTimeInfo
                ? "Site Time Breakdown"
                : "Non-Visit Time"}
            </DialogTitle>
            {dayInfo && (
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {dayInfo.data.length > 0
                    ? "Please find below visits for the selected day/consultant."
                    : "No Booking"}
                </DialogContentText>
              </DialogContent>
            )}
            {dayInfo &&
              dayInfo.data.map((v, index) => (
                <DialogTitle key={v.id} id="alert-dialog-title">
                  {"Visit " +
                    (index + 1) +
                    " : " +
                    v.Account_Name__c +
                    " (" +
                    v.Description__c +
                    ")"}
                  <DialogContent>
                    <DialogContentText>
                      Booking Date :{" "}
                      {moment(v.Visit_Start__c).format("DD/MM/yyyy HH:mm")} -{" "}
                      {moment(v.Visit_End__c).format("HH:mm")}{" "}
                      {v.Actual_Start_Time__c &&
                        "  (actual time : " +
                          v.Actual_Start_Time__c.substring(0, 5) +
                          " - " +
                          v.Actual_End_Time__c.substring(0, 5) +
                          ")"}
                    </DialogContentText>
                    <DialogContentText>
                      Status : {v.Status__c}
                    </DialogContentText>
                    <DialogContentText>
                      Account Consultant : {v.Account_H_S_Consultant__c}
                    </DialogContentText>
                    {(v.Travel_Start_Time__c || v.Travel_End_Time__c) && (
                      <DialogContentText>
                        Travel Time :{" "}
                        {v.Travel_Start_Time__c &&
                          v.Travel_Start_Time__c.substring(0, 5)}{" "}
                        -{" "}
                        {v.Travel_End_Time__c &&
                          v.Travel_End_Time__c.substring(0, 5)}
                      </DialogContentText>
                    )}
                  </DialogContent>
                </DialogTitle>
              ))}
            {siteTimeInfo &&
              siteTimeInfo.data.map(
                (v, index) =>
                  v.Actual_Start_Time__c && (
                    <DialogContent key={v.id}>
                      <DialogContentText>
                        Time :{" "}
                        {Math.floor(v.Actual_Time_Minutes__c / 60) +
                          "hrs " +
                          (v.Actual_Time_Minutes__c % 60) +
                          "mins"}{" "}
                        Date : {moment(v.Visit_Start__c).format("DD/MM/yyyy")}{" "}
                        Account : {v.Account_Name__c} Site Time :{" "}
                        {v.Actual_Start_Time__c.substring(0, 5)} -{" "}
                        {v.Actual_End_Time__c.substring(0, 5)}
                      </DialogContentText>
                    </DialogContent>
                  )
              )}
            {writeUpTimeInfo &&
              writeUpTimeInfo.data.map(
                (v, index) =>
                  v.Write_up_Time_Minutes__c && (
                    <DialogContent key={v.id}>
                      <DialogContentText>
                        Time :{" "}
                        {Math.floor(v.Write_up_Time_Minutes__c / 60) +
                          "hrs " +
                          (v.Write_up_Time_Minutes__c % 60) +
                          "mins"}{" "}
                        Date : {moment(v.Visit_Start__c).format("DD/MM/yyyy")}{" "}
                        Account : {v.Account_Name__c}{" "}
                      </DialogContentText>
                    </DialogContent>
                  )
              )}
            {travelTimeInfo &&
              travelTimeInfo.data.map(
                (v, index) =>
                  v.Actual_End_Time__c !== v.Travel_End_Time__c &&
                  (v.Travel_End_Time__c || v.Travel_Start_Time__c) && (
                    <DialogContent key={v.id}>
                      <DialogContentText>
                        Date : {moment(v.Visit_Start__c).format("DD/MM/yyyy")}{" "}
                        Account : {v.Account_Name__c}
                      </DialogContentText>
                      <DialogContentText>
                        Travel Time To Site :{" "}
                        {v.Travel_Start_Time__c &&
                          v.Travel_Start_Time__c.substring(0, 5)}{" "}
                        -{" "}
                        {v.Actual_Start_Time__c &&
                          v.Actual_Start_Time__c.substring(0, 5)}
                      </DialogContentText>
                      <DialogContentText>
                        Travel Time From Site :{" "}
                        {v.Actual_End_Time__c &&
                          v.Actual_End_Time__c.substring(0, 5)}{" "}
                        -{" "}
                        {v.Travel_End_Time__c &&
                          v.Travel_End_Time__c.substring(0, 5)}
                      </DialogContentText>
                    </DialogContent>
                  )
              )}
            {dayNonVisitInfo && (
              <DialogContent>
                <DialogContentText>
                  Detail : {dayNonVisitInfo}
                </DialogContentText>
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={handleCloseDayInfoDialog} variant="contained">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      )}
    </Card>
  );
}

export default VisitWeeklyView;
