import React, { useState} from 'react';
import { connect } from 'react-redux';
import "../../vendor/perfect-scrollbar.css";
import {
    Tabs,
    Tab, CircularProgress, Typography, makeStyles, Paper
} from "@material-ui/core";
import MyVisits from "./MyVisits";
import VisitWeeklyView from "./VisitWeeklyView";
import TabPanel from "../TabPanel";
import useMyVisits from "../../hooks/queries/useMyVisits";

const useStyles = makeStyles(theme => ({
    spinnerContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tabBar: {
        marginBottom: theme.spacing(4)
    }
}))

const VisitCalendar = ({ user }) => {
    const classes = useStyles()
    const [currentTab, setCurrentTab] = useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const myVisits = useMyVisits({page, rowsPerPage})

    const handleTabChange = (e, newValue) => {
        setCurrentTab(newValue);
    }
    
    const handleMyVisitsPageChange = (newPage) => {
        setPage(newPage)
    }
    
    const handleMyVisitsRowsChange = (newRows) => {
        setRowsPerPage(newRows)
    }

    if (!user)
        return null;
    
    if (myVisits.isLoading)
        return <div className={classes.spinnerContainer}>
            <CircularProgress />        
        </div>
    
    if (myVisits.isError)
        return <Typography>{myVisits.error}</Typography>

    return (
        <div style={{ paddingTop: '10px', maxWidth: 'calc(100vw - 80px)', height: 'calc(100vh - 135px)', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            {myVisits.data?.totalCount > 0 ? (
                <>
                    <Paper className={classes.tabBar}>
                        <Tabs value={currentTab} onChange={handleTabChange}>
                            <Tab label='My Visits' />
                            <Tab label="Weekly View" />
                        </Tabs>
                    </Paper>
                    <TabPanel value={currentTab} index={0}>
                        <MyVisits 
                            visits={myVisits.data}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleMyVisitsPageChange}
                            onRowsChange={handleMyVisitsRowsChange} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        <VisitWeeklyView />
                    </TabPanel>
                </>
            ) : (
                    <VisitWeeklyView />
                )}
        </div>
    )
};

const mapStateToProps = state => ({ user: state.userReducer.user });

export default connect(mapStateToProps)(VisitCalendar);
