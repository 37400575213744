import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import homeService from "../../services/homeService";

const useVisitAutomationWindow = () =>
  useQuery([queryKeys.visitAutomationWindow], () =>
    homeService.fetchVisitAutomationWindow()
  );

export default useVisitAutomationWindow;
