import React, { useEffect, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { enGB } from 'date-fns/esm/locale';
import { useSelector, useDispatch } from 'react-redux'
import { fetchSubmissions, dismissSubmission, fetchHiscoxMembers } from '../../store/slices/registrationSlice';

import {
    Card,
    Box,
    Typography,
    CardContent,
    Button,
    Grid,
} from "@material-ui/core";

const Submission = ({ match }) => {

    const history = useHistory();
    const { id } = match.params;
    
    const { submissions, fetchingSubmissions: fetching, hiscoxMembers } = useSelector((state) => state.registrationReducer);
    const dispatch = useDispatch();

    const submission = useMemo(() => submissions.find(s => s.id === parseInt(id)), [id, submissions]);

    console.log({ submission, id, submissions });

    useEffect(() => {
        if (submissions.length === 0)
            dispatch(fetchSubmissions());

        if (hiscoxMembers.length === 0)
            dispatch(fetchHiscoxMembers());

    }, [dispatch, hiscoxMembers.length, submissions.length]);

    const dismiss = () => {
        dispatch(dismissSubmission(parseInt(id)));
        history.push("/registrations");
    }

    const hiscoxMember = useMemo(() => {

        if (!submission?.broker)
            return null;

        var hiscoxMember = hiscoxMembers.find(x => x.policyNumber === submission.policyNumber)

        return hiscoxMember;

    }, [hiscoxMembers, submission]);

    useEffect(() => {
        if (submission?.broker === "HISCOX") {

        }

    }, [submission]);

    return (
        <>
            {fetching && <h2 style={{ margin: '10px' }}>Loading ...</h2>}
            {!fetching && !submission && <h2 style={{ margin: '10px' }}>Not found</h2>}

            {submission &&
                <>
                <Link to="/registrations">Registrations</Link>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Box display="flex" flexDirection="column" gridGap="10px" flexGrow={1}>
                                    <Box>
                                        <Typography variant="h6">Registration Information</Typography>
                                        <Typography>Submitted: {format(new Date(submission.createdOn), "Pp", { locale: enGB })}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Contact Information</Typography>
                                        <Typography>{submission.firstName} {submission.lastName}</Typography>
                                        <Typography>{submission.email}</Typography>
                                        {submission.phone && <Typography>tel: {submission.phone}</Typography>}
                                        {submission.mobile && <Typography>mobile: {submission.mobile}</Typography>}
                                    </Box>
                                    <Box>
                                        <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Policy/Membership Number</Typography>
                                        <Typography>{submission.policyNumber}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Insurance Broker / Membership Body</Typography>
                                        <Typography>{submission.broker}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Company</Typography>
                                        <Box display="flex" flexDirection="column">
                                            <Typography>{submission.companyName}</Typography>
                                            <Typography>{submission.addressLine1}</Typography>
                                            {submission.addressLine2 && <Typography>{submission.addressLine2}</Typography>}
                                            {submission.addressLine3 && <Typography>{submission.addressLine3}</Typography>}
                                            <Typography>{submission.city}</Typography>
                                            <Typography>{submission.postCode}</Typography>
                                        </Box>
                                    </Box>
                                    <Button variant="contained" color="primary" onClick={dismiss}>Dismiss</Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    {hiscoxMember &&
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Box display="flex" flexDirection="column" gridGap="10px" flexGrow={1}>
                                        <Typography variant="h6">HISCOX Information</Typography> 
                                        <Box>
                                            <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Policy Dates</Typography>
                                            <Typography>{format(new Date(hiscoxMember.startDate), "dd/MM/yy")} - {format(new Date(hiscoxMember.endDate), "dd/MM/yy")}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Policy Number</Typography>
                                            <Typography>{hiscoxMember.policyNumber} ({hiscoxMember.status})</Typography>
                                        </Box>                                        
                                        <Box>
                                            <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Company</Typography>
                                            <Box display="flex" flexDirection="column">
                                                <Typography>{hiscoxMember.name}</Typography>                                               
                                                <Typography>{hiscoxMember.postCode}</Typography>                                               
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography style={{ fontWeight: 500, color: '#7a5cf0' }}>Broker</Typography>
                                            <Box display="flex" flexDirection="column">
                                                <Typography>Channel: {hiscoxMember.channel}</Typography>
                                                {hiscoxMember.brokerCompanyName}
                                                {hiscoxMember.brokerNumber && <> ({hiscoxMember.brokerNumber})</>}
                                                {hiscoxMember.brokerContact && <span style={{ fontSize: '0.8em' }}><br />{hiscoxMember.brokerContact}</span>}                                                
                                            </Box>
                                        </Box>                                        
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
                    
                </>
            }
        </>
    )
};

export default Submission;
