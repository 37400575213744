import React from 'react';

function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            style={{ height: '100%', maxHeight: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div
                    style={{
                        height: '100%',
                        maxHeight: '100%'
                    }}>
                    {children}</div>
            )}
        </div>
    );
}

export default TabPanel;