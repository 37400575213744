import React, { useState } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { WarningTwoTone as WarningIcon } from '@material-ui/icons';
import receptionImg from '../images/reception.jpg';
import worknestLogo from '../images/WorkNest_Purple_Positive_RGB.svg';
import LandingLayout from '../layouts/LandingLayout';
import authService from '../plugins/authService';

const LandingPage = () => {
    const [isChromeBrowser] = useState(true);
    const userState = useSelector(state => state.userReducer);

    const login = async () => {
        try {
            localStorage.setItem('redirect', window.location.pathname);
            console.log('login gettoken');
            await authService.loginRedirect();
        } catch (error) {
            console.error(error);
        }
    };

    const LoginButton = () => {
        if (userState.checkingUserSession)
            return (
                <Grid container justifyContent='center' style={{ padding: 20 }}>
                    <Typography variant='h6'>Checking login</Typography>
                </Grid>
            );

        if (!isChromeBrowser)
            return (
                <Grid
                    container
                    justify='center'
                    alignItems='center'
                    style={{ padding: 20, align: 'center' }}>
                    <Typography border='2px' align='center' variant='h4'>
                        <WarningIcon fontSize='large' color='red' />
                    </Typography>
                    <Typography align='center' variant='h4'>
                        Please use the Google Chrome browser
                    </Typography>
                </Grid>
            );

        return (
            <Button variant='contained' onClick={login}>Login</Button>
        );
    };

    return (
        <LandingLayout>
            <Grid
                container
                style={{ minheight: '100vh' }}
                justifyContent='space-between'>
                <Grid item xs={12} sm={6}>
                    <img
                        src={receptionImg}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        alt='Legal'
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    alignItems='center'
                    direction='column'
                    justifyContent='space-between'
                    style={{ padding: 10 }}>
                    <div />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 300,
                            minWidth: 200,
                        }}>
                        <Grid
                            container
                            justifyContent='center'
                            style={{ padding: 20 }}>
                            <img
                                src={worknestLogo}
                                alt='logo'
                                width={300}
                            />
                        </Grid>

                        <LoginButton />
                    </div>
                    <Typography variant='h1'>WORKNEST TOOLS</Typography>
                    <div />
                </Grid>
            </Grid>
        </LandingLayout>
    );
};

export default LandingPage;
