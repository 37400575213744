import React, { useState, useEffect, useRef, useMemo } from "react";
import { format } from "date-fns";
import axios from "../../plugins/axios";
import { useSelector, useDispatch } from 'react-redux'
import { fetchHiscoxMembers } from '../../store/slices/registrationSlice'
import {
    Box,
    Button,
    TextField,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    TablePagination,
} from "@material-ui/core";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;
 
const Hiscox = () => {   

    const [submitting, setSubmitting] = useState(false);    
    const fileInputRef = useRef();

    const { hiscoxMembers: members, fetchingHiscoxMembers: fetching } = useSelector((state) => state.registrationReducer);
    const dispatch = useDispatch();   

    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(0);   

    const rowsPerPage = 10;

    useEffect(() => {
        if (members.length === 0)
            dispatch(fetchHiscoxMembers());
    }, [dispatch, members.length]);

    const activeFilter = useMemo(() => {
        if (filter.length < 3)
            return "";

        setPage(0);

        return filter.toLowerCase();
    }, [filter]);    

    const filteredMembers = useMemo(() => {

        let filtered = members || [];

        if (activeFilter !== "")
            filtered = filtered.filter(f => f.name.toLowerCase().includes(activeFilter));

        return filtered;

    }, [activeFilter, members]);

    const displayedMembers = useMemo(() => {

        let displayed = filteredMembers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        return displayed;
    }, [filteredMembers, page]);

    const handleUpload = async (e) => {
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append("file", e.target.files[0]);

            setSubmitting(true);
            try {
                let result = await axios.post(
                    `${apiUrl}/registration/upload`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                console.log(result.data);
                setPage(0);
                dispatch(fetchHiscoxMembers());
            }
            catch (err) {
                console.error(err);
            }
            finally {
                setSubmitting(false);
                fileInputRef.current.value = null;
            }
        }
    };  

    return (
        <>
            <Box display="flex" style={{ marginTop: '10px' }} alignItems="center" gridGap="10px" flexWrap="wrap">
                <TextField
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    variant="outlined"
                    size="small"
                    label="filter"
                />                
                <Button
                    onClick={() => fileInputRef.current.click()}
                    variant="contained"
                    disabled={submitting}
                    color="primary" >
                    Upload
                </Button>
                <input
                    hidden
                    ref={fileInputRef}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    type="file"
                    onChange={handleUpload}
                />
            </Box>
            {fetching && <h2 style={{ margin: '10px' }}>Loading ...</h2>}
            {!fetching && members.length === 0 && <h2 style={{ margin: '10px' }}>No entries</h2>}
            {!fetching && members.length > 0 &&
                <Table size="small">
                    <TableHead>
                        <TableRow style={{ height: '37px' }}>
                            <TableCell>Name</TableCell>
                            <TableCell>Policy Number</TableCell>
                            <TableCell>Post Code</TableCell>
                            <TableCell>Start</TableCell>
                            <TableCell>End</TableCell>
                            <TableCell>Endorsement</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Channel</TableCell>
                            <TableCell>Broker</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayedMembers.map(m =>
                            <TableRow key={m.policyNumber}>
                                <TableCell>{m.name}</TableCell>
                                <TableCell>{m.policyNumber}</TableCell>
                                <TableCell>{m.postCode}</TableCell>
                                <TableCell>{format(new Date(m.startDate), "dd/MM/yyyy")}</TableCell>
                                <TableCell>{format(new Date(m.endDate), "dd/MM/yyyy")}</TableCell>
                                <TableCell>{format(new Date(m.endorsementDate), "dd/MM/yyyy")}</TableCell>
                                <TableCell>{m.status}</TableCell>
                                <TableCell>{m.channel}</TableCell>
                                <TableCell>
                                    {m.brokerCompanyName}
                                    {m.brokerNumber && <> ({m.brokerNumber})</>}
                                    {m.brokerContact && <span style={{ fontSize: '0.8em' }}><br />{m.brokerContact}</span>}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                count={filteredMembers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, newPage) => setPage(newPage)}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            }
        </>
        
    )
};

export default Hiscox;
