import * as types from '../constants';

export function setUser(user) {
    return {
        type: types.SET_USER,
        user: user,
    };
}

export function setCheckingUserSession(isChecking) {
    return {
        type: types.SET_CHECKING_USER_SESSION,
        isChecking,
    };
}
