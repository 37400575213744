import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import {
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    TextField
} from "@material-ui/core";


const InsertPlaceholder = ({ editor, placeholders }) => {

    const [filter, setFilter] = useState("");   

    const filteredPlaceholders = useMemo(() => {
        let term = filter.trim().toLowerCase();        

        if (!term)
            return placeholders;

        return placeholders.filter(p => p.key.toLowerCase().includes(term));

    }, [placeholders, filter]);    

    const insertPlaceholder = (key) => {
        editor.execute("placeholder", { key });
        editor.editing.view.focus();
    }

    return (
        <FormControl variant="outlined" margin="dense">
            <InputLabel>Insert Placeholder</InputLabel>
            <Select
                style={{ width: '250px' }}
                displayEmpty
                value=""
                label="Insert Placeholder"
                fullWidth
                onChange={e => insertPlaceholder(e.target.value)}
            >      
                <MenuItem>
                    <TextField
                        onKeyDown={e => { e.stopPropagation(); }}
                        onClick={e => { e.stopPropagation(); }}
                        fullWidth
                        value={filter}
                        onChange={e => setFilter(e.target.value)}
                    />
                </MenuItem>              
                {
                    filteredPlaceholders.map(p => <MenuItem key={p.key} value={p.key}>{p.key}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
};

const mapStateToProps = (state) => ({ placeholders: state.configReducer.placeholders });

export default connect(mapStateToProps)(InsertPlaceholder);