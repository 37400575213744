import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL

const fetchVisitAutomationWindow = async () => {
  try {
    const response = await axios.get(`${apiUrl}/visitautomationwindow`);
    return response.data;
  } catch (e) {
    console.error(e);
    return Promise.reject(
      e?.message || "Could not fetch visit automation window."
    );
  }
};

const homeService = {
  fetchVisitAutomationWindow,
};

export default homeService;
