import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import axios from "../plugins/axios";
import { TimePicker, DatePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import moment from "moment";
import { useDropzone } from "react-dropzone";

import {
    Box,
    TextField,
    FormControl,
    Typography,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Select,
    MenuItem,
    Button,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";

const IconButton = styled(Button)`
  padding: 2px;
  margin-top: -2px;
  margin-left: 2px;
  min-width: 0;
  color: ${grey[700]};
`;

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Paper = styled(MuiPaper)(spacing);

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const TimeSheet = ({ user }) => {
    const [timeEntries, setTimeEntries] = useState([]);
    const [visits, setVisits] = useState([]);
    const [employeeId, setEmployeeId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [open4, setOpen4] = useState(false);
    const [options4, setOptions4] = useState([]);
    const [input4, setInput4] = useState("");
    const [loading4, setLoading4] = useState(false);

    const [entry, setEntry] = useState({});
    const contracts = entry.contracts || [];

    const [nextId, setNextId] = useState(1);

    const [activityTypes, setActivityTypes] = useState([]);

    const [selectedDate, setSelectedDate] = useState(new Date());

    const addVisible = useMemo(() => {
        if (entry.accountId && !entry.contractId) return false;

        if (!entry.accountId && !entry.description) return false;

        if (!entry.activityType) return false;

        if (entry.duration === "Invalid" || entry.duration === "") return false;

        return true;
    }, [entry]);

    const waitingEntries = useMemo(() => {
        return timeEntries.filter((e) => e.employeeId == null);
    }, [timeEntries]);

    const submitVisible = useMemo(() => {
        return !submitting && waitingEntries.length > 0;
    }, [waitingEntries, submitting]);

    useEffect(() => {
        let active = true;

        if (!input4 || input4.length < 3) {
            setOptions4([]);
            return undefined;
        }
        (async () => {
            setLoading4(true);
            const clients = (
                await axios.get(`${apiUrl}/getclients/` + encodeURIComponent(input4))
            ).data;

            if (active) {
                setOptions4(clients);
            }
            setLoading4(false);
        })();

        return () => {
            active = false;
        };
    }, [open4, input4]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            let existing = entry.files || [];
            let newFiles = acceptedFiles.filter(
                (f) => existing.filter((e) => e.path === f.path).length === 0
            );
            setEntry({
                ...entry,
                files: [...existing, ...newFiles],
            });
            console.log("e", existing, acceptedFiles);
        },
        [entry]
    );

    const handleAccountChange = (e, v) => {
        console.log("handleAccountChange", v);
        setEntry({
            ...entry,
            accountId: v && v.value,
            accountName: v && v.text,
        });
    };

    useEffect(() => {
        if (entry.accountId == null) {
            setEntry({ ...entry, contracts: [], contractId: "" });
        } else {
            (async () => {
                var result = await axios.get(`${apiUrl}/accountcontracts/${entry.accountId}`);
                let contracts = result.data;
                setEntry({
                    ...entry,
                    contracts,
                    contractId: contracts.length === 1 ? contracts[0].key : "",
                });
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entry.accountId]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const updateEntry = (field, value) => {
        setEntry({
            ...entry,
            [field]: value,
        });
    };

    const updateTimeEntry = (field, value) => {
        var dt = new Date(selectedDate);
        dt.setHours(value.getHours());
        dt.setMinutes(value.getMinutes());
        dt.setSeconds(0);
        console.log(dt);
        updateEntry(field, dt);
    };

    const calcDuration = (start, end) => {
        var duration = "";

        if (start && end) {
            let totalMinutes = moment(end).diff(start, "minutes");
            console.log(totalMinutes);
            let hours = Math.floor(totalMinutes / 60);
            let minutes = totalMinutes - hours * 60;
            duration =
                totalMinutes <= 0
                    ? "Invalid"
                    : `${hours}:${minutes.toString().padStart(2, "0")}`;
        }

        return duration;
    };

    useEffect(() => {
        setEntry((prevEntry) => ({
            ...prevEntry,
            duration: calcDuration(prevEntry.startTime, prevEntry.endTime),
        }));
    }, [entry.startTime, entry.endTime]);

    const addEntry = () => {
        setTimeEntries([...timeEntries, { ...entry, id: nextId }]);
        setNextId(nextId + 1);
        clearEntry();
    };

    const clearEntry = () => {
        setEntry({});
        setInput4("");
        setOptions4([]);
    };

    const deleteEntry = (id) => {
        setTimeEntries(timeEntries.filter((e) => e.id !== id));
    };

    const getInitialData = useCallback(async () => {
        if (user) {
            setLoading(true);
            var response = await axios.get(`${apiUrl}/timesheetinitial`);
            setLoading(false);
            setEmployeeId(response.data.employeeId);
            setVisits(response.data.visits || []);
            setActivityTypes(response.data.activityTypes);
        }
    }, [user]);

    const getTimeEntries = useCallback(async () => {
        try {
            setLoading(true);
            var response = await axios.get(
                `${apiUrl}/employeetimedata/${moment(selectedDate).format(
                    "YYYY-MM-DD"
                )}`
            );
            var entries = response.data || [];
            entries.forEach(
                (e) => (e.duration = calcDuration(e.startTime, e.endTime))
            );

            setTimeEntries(entries);
        } finally {
            setLoading(false);
        }
    }, [selectedDate]);

    useEffect(() => {
        getInitialData();
    }, [user, getInitialData]);

    useEffect(() => {
        if (employeeId) {
            clearEntry();
            getTimeEntries();
        }
    }, [employeeId, getTimeEntries, selectedDate]);

    const submit = async () => {
        setSubmitting(true);
        try {
            await Promise.all(
                waitingEntries.map(async (e) => {
                    var formData = new FormData();

                    if (e.files) {
                        e.files.forEach((file) => {
                            formData.append("files", file);
                        });
                    }

                    formData.append("entryJson", JSON.stringify(e));

                    await axios.post(
                        `${apiUrl}/addtimesheetentry/${employeeId}`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    );
                })
            );
        } finally {
            setSubmitting(false);
        }

        await getTimeEntries();
    };

    const updateVisitMinutes = (visitId, minutes) => {
        setVisits(visits.map((v) => (v.id === visitId ? { ...v, minutes } : v)));
    };

    const confirmVisit = async (visit) => {
        setVisits(
            visits.map((v) => (v.id === visit.id ? { ...v, submitting: true } : v))
        );
        await axios.post(`${apiUrl}/confirmVisitMinutes`, visit);
        setVisits(visits.filter((v) => v.id !== visit.id));
    };

    const removeFile = (file) => {
        setEntry({
            ...entry,
            files: entry.files.filter((f) => f !== file),
        });
    };

    if (!user)
        return null;

    if (loading)
        return <h1>Loading ...</h1>;

    if (!user && !loading && !employeeId)
        return <h1>Employee not registered in Salesforce!</h1>;

    return (
        <Box display="flex" flexDirection="column" style={{ paddingTop: "10px" }}>
            <Box display="flex" alignItems="center">
                <Typography variant="h3" style={{ marginRight: "20px" }}>
                    Daily Time Sheet - {user.account.name}
                </Typography>
                <DatePicker
                    margin="normal"
                    format="dd/MM/yyyy"
                    value={selectedDate}
                    onChange={setSelectedDate}
                    inputProps={{ style: { textAlign: "center", width: "100px" } }}
                />
            </Box>
            <Divider my={2} />
            <Card m={6}>
                <CardContent pb={1}>
                    <Typography variant="h6" gutterBottom>
                        Time Entries
                    </Typography>
                </CardContent>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Account</TableCell>
                                <TableCell>Contract</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell align="center">Activity Type</TableCell>
                                <TableCell align="center">Start</TableCell>
                                <TableCell align="center">End</TableCell>
                                <TableCell align="center">Duration</TableCell>
                                <TableCell>Files</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {timeEntries.map((entry) => (
                                <TableRow key={entry.id}>
                                    <TableCell>{entry.accountName}</TableCell>
                                    <TableCell>{entry.contractId && entry.contracts.find((x) => x.key === entry.contractId).value}</TableCell>
                                    <TableCell>{entry.description}</TableCell>
                                    <TableCell align="center">{entry.activityType}</TableCell>
                                    <TableCell align="center">{moment(entry.startTime).format("HH:mm")}</TableCell>
                                    <TableCell align="center">{moment(entry.endTime).format("HH:mm")}</TableCell>
                                    <TableCell align="center">{entry.duration}</TableCell>
                                    <TableCell>
                                        {entry.files && (
                                            <ul>
                                                {entry.files.map((file) => (
                                                    <li key={file.path}>{file.path}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        {!entry.employeeId && (
                                            <IconButton onClick={() => deleteEntry(entry.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>
                                    <Autocomplete
                                        freeSolo
                                        inputValue={input4}
                                        open={open4}
                                        onOpen={() => setOpen4(true)}
                                        onClose={() => setOpen4(false)}
                                        getOptionSelected={(option, value) =>
                                            option.value === value.value
                                        }
                                        getOptionLabel={(option) => option.text}
                                        onChange={handleAccountChange}
                                        options={options4}
                                        loading={loading4}
                                        onInputChange={(e, val) => setInput4(val)}
                                        renderInput={(params) => {
                                            params.inputProps.autoComplete = "new-password";
                                            params.inputProps.style = { minWidth: "200px" };
                                            return <TextField {...params} fullWidth />;
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl m={2} style={{ width: "100%" }}>
                                        <Select
                                            displayEmpty
                                            value={entry.contractId || ""}
                                            onChange={(e) =>
                                                updateEntry("contractId", e.target.value)
                                            }
                                        >
                                            {contracts.length === 0 && entry.accountId == null && (
                                                <MenuItem value="">
                                                    <em>&lt;-- choose Account</em>
                                                </MenuItem>
                                            )}
                                            {contracts.length === 0 && entry.accountId && (
                                                <MenuItem value="">
                                                    <em>none available</em>
                                                </MenuItem>
                                            )}
                                            {contracts.length > 1 && (
                                                <MenuItem value="">
                                                    <em>select contract</em>
                                                </MenuItem>
                                            )}
                                            {contracts.map((c) => (
                                                <MenuItem key={c.key} value={c.key}>
                                                    {c.value}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        inputProps={{ maxLength: 255 }}
                                        value={entry.description || ""}
                                        onChange={(e) => updateEntry("description", e.target.value)}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <FormControl m={2} style={{ width: "100%" }}>
                                        <Select
                                            value={entry.activityType || ""}
                                            onChange={(e) =>
                                                updateEntry("activityType", e.target.value)
                                            }
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {activityTypes.map((l) => (
                                                <MenuItem key={l} value={l}>
                                                    {l}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell align="center">
                                    <TimePicker
                                        ampm={false}
                                        margin="normal"
                                        value={entry.startTime || null}
                                        onChange={(value) => updateTimeEntry("startTime", value)}
                                        inputProps={{
                                            style: { textAlign: "center", width: "100px" },
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <TimePicker
                                        ampm={false}
                                        margin="normal"
                                        value={entry.endTime || null}
                                        onChange={(value) => updateTimeEntry("endTime", value)}
                                        inputProps={{
                                            style: { textAlign: "center", width: "100px" },
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="center">{entry.duration}</TableCell>
                                <TableCell>
                                    <section>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <Box display="flex" alignItems="center">
                                                <CloudUploadIcon style={{ marginRight: "5px" }} />
                                                <Typography style={{ cursor: "default" }}>Drag and drop your files here or <strong>click</strong> to browse</Typography>
                                            </Box>
                                            {entry.files && (
                                                <ul>
                                                    {entry.files.map((file) => (
                                                        <li key={file.path}>
                                                            <Box alignItems="center" display="flex">
                                                                <Box>{file.path} </Box>
                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        removeFile(file);
                                                                    }}
                                                                >
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            </Box>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Box>
                                    </section>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton disabled={!addVisible} onClick={addEntry}>
                                        <AddIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Button
                        variant="contained"
                        disabled={!submitVisible}
                        onClick={submit}
                    >
                        {submitting ? "Submitting..." : "Submit"}
                    </Button>
                </Paper>
            </Card>
            {visits.length > 0 && (
                <React.Fragment>
                    <Card m={6}>
                        <CardContent pb={1}>
                            <Typography variant="h6" gutterBottom>
                                Visit Write-up Time
                            </Typography>
                        </CardContent>
                        <Paper>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Account</TableCell>
                                        <TableCell align="center">VisitDate</TableCell>
                                        <TableCell align="center">Write-up Minutes</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {visits.map((visit) => (
                                        <TableRow key={visit.id}>
                                            <TableCell>{visit.accountName}</TableCell>
                                            <TableCell align="center">
                                                {moment(visit.visitDate).format("DD/MM/YY")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {visit.vrfComplete ? (
                                                    <TextField
                                                        type="number"
                                                        value={visit.minutes || ""}
                                                        inputProps={{ style: { textAlign: "center" } }}
                                                        onChange={(e) =>
                                                            updateVisitMinutes(visit.id, e.target.value)
                                                        }
                                                    />
                                                ) : (
                                                    "Awaiting VRF"
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {visit.vrfComplete && (
                                                    <Button
                                                        variant="contained"
                                                        disabled={visit.submitting || !visit.minutes}
                                                        onClick={() => confirmVisit(visit)}
                                                    >
                                                        {visit.submitting ? "sending .." : "Confirm"}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Card>
                </React.Fragment>
            )}
        </Box>
    );
};

const mapStateToProps = (state) => ({ user: state.userReducer.user });

export default connect(mapStateToProps)(TimeSheet);
