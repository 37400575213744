import React from "react";

import {
    Home as HomeIcon
} from "react-feather";

import Home from "../components/Home";
import CompleteSurvey from "../components/survey/CompleteSurvey";
import TimeSheet from "../components/TimeSheet";
import WeeklySummary from "../components/WeeklySummary";
import VisitCalendar from "../components/visits/VisitCalendar";
import Opportunities from "../components/Opportunities";
import Targets from "../components/targets/Targets";
import Registrations from "../components/registrations/Registrations";
import Submission from "../components/registrations/Submission";
import SalesProto from "../components/SalesProto";
import Documents from "../components/documents/Documents";
import Templates from "../components/templates/Templates";
import TemplateDocument from "../components/templates/TemplateDocument";
import AIPlayground from "../components/AIPlayground";
import RecordingPlayer from "../components/RecordingPlayer";
import MeetingPlayer from "../components/MeetingPlayer";
import Marketing from "../components/pardot/Marketing";

const Blank = () => "Blank";

const homeRoutes = {
    id: "Home",
    path: "/",
    icon: <HomeIcon />,
    component: Home
}

const protoRoutes = {
    id: "SalesProto",
    path: "/sales-proto",
    name: "SalesProto",
    component: SalesProto
};

// This route is not visible in the sidebar
const privateRoutes = {
    id: "Private",
    path: "/private",
    component: Blank,
    children: [
        {
            id: "CompleteSurvey",
            path: "/complete-survey/:identifier",
            name: "CompleteSurvey",
            component: CompleteSurvey
        },
        {
            id: "CompleteForm",
            path: "/complete-form/:surveyKey",
            name: "CompleteForm",
            component: CompleteSurvey
        },
        {
            id: "TimeSheet",
            path: "/time-sheet",
            name: "TimeSheet",
            component: TimeSheet
        },
        {
            id: "WeeklySummary",
            path: "/weekly-summary",
            name: "WeeklySummary",
            component: WeeklySummary
        },
        {
            id: "VisitCalendar",
            path: "/visit-calendar",
            name: "VisitCalendar",
            component: VisitCalendar
        },
        {
            id: "Opportunities",
            path: "/opportunities",
            name: "Opportunities",
            component: Opportunities
        },
        {
            id: "Marketing",
            path: "/marketing",
            name: "Marketing",
            component: Marketing,
            requiresRole: "Marketing"
        },
        {
            id: "Targets",
            path: "/targets",
            name: "Targets",
            component: Targets,
            requiresRole: 'Targets'
        },
        {
            id: "Documents",
            path: "/documents",
            name: "Documents",
            component: Documents
        },
        {
            id: "Templates",
            path: "/templates",
            name: "Templates",
            component: Templates
        },
        {
            id: "TemplateDocument",
            path: "/template-document",
            name: "TemplateDocument",
            component: TemplateDocument
        },
        {
            id: "AIPlayground",
            path: "/ai",
            name: "AIPlayground",
            component: AIPlayground,
            requiresRole: 'AI'
        },
        {
            id: "RecordingPlayer",
            path: "/recording-player/:recordingSid",
            name: "RecordingPlayer",
            component: RecordingPlayer
        },
        {
            id: "MeetingPlayer",
            path: "/meeting-player/:recordingId",
            name: "MeetingPlayer",
            component: MeetingPlayer
        },
        {
            id: "Registrations",
            path: "/registrations",
            name: "Registrations",
            component: Registrations,
            requiresRole: 'Registrations'
        },
        {
            id: "Registration",
            path: "/registration/:id",
            name: "Registration",
            component: Submission,
            requiresRole: 'Registrations'
        },
    ]
};

// routes with dashboard layout
export const dashboard = [    
    privateRoutes,
    homeRoutes
];

// routes with proto layout
export const proto = [
    protoRoutes
];

// visible Menu
export default [   
    homeRoutes
];
