import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import advisoryIcon from './images/advisory.svg';
import deepenIcon from './images/deepen.svg';
import coreAdvisoryIcon from './images/coreAdvisory.svg';
import saasIcon from './images/saas.svg';
import { makeStyles } from '@material-ui/core/styles';
import TargetTableHeader from './TargetTableHeader';
import TargetTableRow from './TargetTableRow';
import { setPillarIdAction } from '../../store/actions/targetActions';

import {
    Box,
    IconButton,
    Table,
    TableBody
} from "@material-ui/core";

import {    
    ArrowForward
} from "@material-ui/icons"

const useStyles = makeStyles(() => ({
    table: {
        color: '#0a0047',
        "& tbody tr": {
            borderTop: '2px solid #d6d3e3'
        },
        "& td": {
            paddingLeft: '5px',
            height: '59px'
        },
        "& th": {
            paddingLeft: 0
        }
    }
}));

const BalancedScoreCard = ({ pillarId, targetState, setPillarId }) => {

    const { targets, pillars } = targetState;

    const classes = useStyles();

    const icons = [
        advisoryIcon,
        coreAdvisoryIcon,
        saasIcon,
        deepenIcon
    ]; 

    const pillar = pillars.find(x => x.id === pillarId);

    const myTargets = useMemo(() => {
        return targets.filter((x => x.isBalanceScoreCard && x.pillarId === pillarId))
    }, [pillarId, targets]);  
    
    return (
        <Box style={{ border: '1px solid #aaa5c3', borderRadius: '3px' }}>
            <Box display="flex" style={{ backgroundColor: '#f2f4fe', padding: '10px' }} alignItems="center">
                <img src={icons[0]} style={{ height: '30px', margin: '10px' }} alt={pillar.name} />
                <Box flexGrow={1} style={{ fontFamily: 'Times New Roman', fontSize: '1.5em' }}>{pillar.name}</Box>
                <IconButton onClick={()=>setPillarId(pillarId)}><ArrowForward /></IconButton>
            </Box>
            <Box style={{ backgroundColor: '#e2dff0', padding: '10px 20px' }}>
                <Table className={classes.table} size="small">
                    <TargetTableHeader />
                    <TableBody>{myTargets.map(t => <TargetTableRow key={t.targetId} target={t} />) }</TableBody>
                </Table>
            </Box>
        </Box>
    )
}


const mapStateToProps = state => ({ targetState: state.targetReducer });

const mapDispatchToProps = dispatch => {
    return {
        setPillarId: (pillarId) => dispatch(setPillarIdAction(pillarId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalancedScoreCard);
