import React from "react";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import ContactLookup from "./ContactLookup";
import AccountLookup from "./AccountLookup"; 
import OpportunityLookup from "./OpportunityLookup";
import TeamLookup from "./TeamLookup";
import EmployeeLookup from "./EmployeeLookup";
import FileUpload from "./FileUpload";

import moment from "moment";
import {
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormControl,
    FormHelperText,
} from "@material-ui/core";

const SurveyQuestion = ({ question, updateAnswer, findAccountId }) => {   

    return (
        <div style={{ padding: "10px" }}>
            {question.required && (
                <div style={{ color: "red", float: "left" }} title="Required">
                    *&nbsp;
                </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: question.questionText }}></div>
            {question.questionType === 0 && (
                <TextField
                    error={!!question.error}
                    helperText={question.error}
                    fullWidth
                    inputProps={{ maxLength: question.maxLength }}
                    value={question.answer || ""}
                    required={question.required}
                    onChange={(e) => updateAnswer(question.questionId, e.target.value)}
                />
            )}
            {question.questionType === 1 && (
                <TextField
                    error={!!question.error}
                    helperText={question.error}
                    fullWidth
                    multiline
                    minRows={4}
                    value={question.answer || ""}
                    required={question.required}
                    onChange={(e) => updateAnswer(question.questionId, e.target.value)}
                />
            )}
            {question.questionType === 2 && (
                <FormControl error={!!question.error}>
                    <RadioGroup
                        value={question.answer || ""}
                        onChange={(e) => updateAnswer(question.questionId, e.target.value)}
                    >
                        {question.choices.map((choice) => (
                            <FormControlLabel
                                key={choice}
                                value={choice}
                                control={<Radio />}
                                label={choice}
                            />
                        ))}
                    </RadioGroup>
                    {question.error && <FormHelperText>{question.error}</FormHelperText>}
                </FormControl>
            )}
            {question.questionType === 3 && (
                <TextField
                    error={!!question.error}
                    helperText={question.error}
                    fullWidth
                    value={question.answer || ""}
                    required={question.required}
                    onChange={(e) => updateAnswer(question.questionId, e.target.value)}
                />
            )}
            {question.questionType === 4 && <AccountLookup question={question} updateAnswer={updateAnswer} />}
            {question.questionType === 5 && (
                <DatePicker
                    error={!!question.error}
                    helperText={question.error}
                    margin="normal"
                    format="dd/MM/yyyy"
                    value={question.dateAnswer || null}
                    onChange={value => updateAnswer(question.questionId, moment(value).format("DD/MM/yyyy"), value)}
                    inputProps={{ style: { textAlign: "center", width: "100px" } }}
                />
            )}
            {question.questionType === 6 && (
                <TimePicker
                    ampm={false}
                    error={!!question.error}
                    helperText={question.required && !question.answer && "Required"}
                    margin="normal"
                    format="HH:mm"
                    value={question.dateAnswer || null}
                    onChange={value => updateAnswer(question.questionId, moment(value).format("HH:mm"), value)}
                    inputProps={{ style: { textAlign: "center", width: "100px" } }}
                />
            )}
            {question.questionType === 7 && <EmployeeLookup question={question} updateAnswer={updateAnswer} />}
            {question.questionType === 8 && <TeamLookup question={question} updateAnswer={updateAnswer} />}
            {question.questionType === 9 && <OpportunityLookup question={question} updateAnswer={updateAnswer} />}
            {question.questionType === 10 && <ContactLookup question={question} updateAnswer={updateAnswer} accountId={findAccountId(question)} />}
            {question.questionType === 11 && <FileUpload question={question} updateAnswer={updateAnswer} />}
        </div>
    );
};

export default SurveyQuestion;
