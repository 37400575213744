export const getNodesByType = function (writer, type, root) {
    const nodes = [];
    const range = writer.createRangeIn(root);

    for (const value of range.getWalker({ ignoreElementEnd: true })) {
        const node = value.item;      

        if (node.name === type) {
            nodes.push(node);
        } 
    }
    
    return nodes;
};

export const setPlaceholderValue = (editor, key, value) =>  {

    editor.model.change(writer => {

        const root = editor.model.document.getRoot();
        const elements = getNodesByType(writer, 'placeholder', root);
        for (const element of elements) {
            const placeholder = element.getAttribute('placeholder');
           
            if (placeholder.key === key) {
                writer.setAttribute('value', value, element);
            }
        }       
    });
};

export const setSectionContent = (editor, key, content, callback) => {

    editor.model.change(writer => {

        const root = editor.model.document.getRoot();
        const elements = getNodesByType(writer, 'htmlSection', root);
        for (const e of elements) {
            const htmlAttributes = e.getAttribute('htmlAttributes');
            const sectionKey = htmlAttributes.attributes['data-key'];          
            
            if (sectionKey === key) {
                // empty content
                let children = [];          
                               
                for (let child of e.getChildren()) {
                    children.push(child);                    
                }

                for (let child of children) {
                    writer.remove(child);
                }

                if (content) {
                    const viewFragment = editor.data.processor.toView(content);
                    const modelFragment = editor.data.toModel(viewFragment);

                    editor.model.insertContent(modelFragment, writer.createPositionAt(e, 0));
                    const attributes = htmlAttributes.attributes;
                    delete attributes.hidden;
                    writer.setAttribute('htmlAttributes', { ...htmlAttributes, attributes: { ...htmlAttributes.attributes }, styles: { display: 'block' } }, e);              
                }
                else {                    
                    writer.setAttribute('htmlAttributes', { ...htmlAttributes, attributes: { ...htmlAttributes.attributes }, styles: { display: 'none' } }, e);
                }                
            }
        }

        if (callback)
            callback();
    });
}


