import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ragColors from '../../constants/ragColors';
import { setTargetValueAction } from '../../store/actions/targetActions';
import Alert from '@material-ui/lab/Alert';

import {
    Box,
    DialogTitle,
    DialogContent,
    TextField
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    textFieldRed: {
        "& .MuiFormLabel-root": {
            color: "red !important"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "red !important"
        }        
    },
    textFieldAmber: {
        "& .MuiFormLabel-root": {
            color: "#ffbf00 !important"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#ffbf00 !important"
        }
    },
    textFieldGreen: {
        "& .MuiFormLabel-root": {
            color: "green !important"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "green !important"
        }
    }
}));

const EditTargetActual = ({ setTargetValue, targetState }) => {

    const { editingTarget: target, targets } = targetState;

    const childTargets = useMemo(() => targets.filter(t => t.rollupTargetId === target.targetId), [target.targetId, targets]);

    const missingActuals = useMemo(() => childTargets.filter(t => t.actual === null), [childTargets]);

    const disableChanges = useMemo(() => target.areaId === 0, [target.areaId]);

    const classes = useStyles();    

    const rangeErrorMessage = "Required when not within GREEN range";

    const ragColor = useCallback(target => {

        if (target.actual === "")
            return ragColors.NONE;
        
        if (target.amberLower == null && target.amberUpper == null && target.redLower == null && target.redUpper == null)
            return ragColors.NONE;
        
        if ((target.redUpper && target.actual >= target.redUpper) || (target.redLower && target.actual <= target.redLower))
            return ragColors.RED;
        
        if ((target.amberUpper && target.actual >= target.amberUpper) || (target.amberLower && target.actual <= target.amberLower))
            return ragColors.AMBER;
        
        return ragColors.GREEN;

    }, []);

    const ragClass = target => {
        const color = ragColor(target);

        switch (color) {
            case ragColors.GREEN:
                return classes.textFieldGreen;
            case ragColors.AMBER:
                return classes.textFieldAmber;
            case ragColors.RED:
                return classes.textFieldRed;
            default:
                return "";
        }
    };    

    return (
        <React.Fragment>
            <DialogTitle>
                {target.description}
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gridGap="10px">
                    {missingActuals.length > 0 && <Alert severity="error">{missingActuals.length} out of {childTargets.length} targets are missing actual value.</Alert>}
                    <TextField
                        type="number"
                        label="Target"
                        value={target.target}
                        disabled
                        onChange={(e) => setTargetValue("target", e.target.value)}
                    />
                    <TextField
                        disabled={disableChanges}
                        type="number"
                        error={ragColor(target) !== ragColors.NONE}
                        className={ragClass(target)}
                        label="Actual"
                        value={target.actual || ""}
                        onChange={(e) => setTargetValue("actual", e.target.value)}
                    />
                    <TextField
                        label="Fact"
                        inputProps={{ maxLength: 255 }}
                        error={!target.fact && ragColor(target) !== ragColors.GREEN}
                        value={target.fact || ""}
                        helperText={!target.fact && ragColor(target) !== ragColors.GREEN && rangeErrorMessage}
                        onChange={(e) => setTargetValue("fact", e.target.value)}
                    />
                    <TextField
                        label="Cause"
                        inputProps={{ maxLength: 255 }}
                        error={!target.cause && ragColor(target) !== ragColors.GREEN}
                        value={target.cause || ""}
                        helperText={!target.cause && ragColor(target) !== ragColors.GREEN && rangeErrorMessage}
                        onChange={(e) => setTargetValue("cause", e.target.value)}
                    />
                    <TextField
                        label="Action"
                        inputProps={{ maxLength: 255 }}
                        disabled={!!target.actionCompletedOn}
                        error={!target.action && ragColor(target) !== ragColors.GREEN}
                        value={target.action || ""}
                        helperText={!target.action && ragColor(target) !== ragColors.GREEN && rangeErrorMessage}
                        onChange={(e) => setTargetValue("action", e.target.value)}
                    />
                </Box>
            </DialogContent>            
        </React.Fragment>
    )
};

const mapStateToProps = state => ({ targetState: state.targetReducer });

const mapDispatchToProps = dispatch => {
    return {
        setTargetValue: (field, value) => dispatch(setTargetValueAction(field, value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTargetActual);

