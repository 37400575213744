import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setTargetValueAction } from '../../store/actions/targetActions';

import {
    Box,
    DialogTitle,
    DialogContent,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Switch,
    FormControlLabel,
    FormHelperText,
    Button as MuiIconButton,
    withStyles
} from "@material-ui/core";

import {
    Add as AddIcon,
    Delete as DeleteIcon    
} from "@material-ui/icons";

const styles = {
    root: {
        minWidth: 0,
        padding: 2,
        '& svg': {
            fontSize: 16
        }
    }
};

const IconButton = withStyles(styles)(MuiIconButton);

const EditTargetInfo = ({ targetState, setTargetValue }) => {

    const { editingTarget: target, pillars, areas, targets } = targetState;
    const [initialChildIds, setInitialChildIds] = useState([]);

    useEffect(() => {
        setInitialChildIds(target.childTargetIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const availablePillarTargets = useMemo(() =>
        targets.filter(t =>
            t.pillarId === target.pillarId
            && (t.rollupTargetId == null || initialChildIds.includes(t.targetId))
            && t.areaId !== 0
            && t.targetType === target.targetType
            && !target.childTargetIds.includes(t.targetId)),
        [targets, target, initialChildIds]
    );

    console.log('availablePillarTargets', availablePillarTargets);

    const addChild = id => {
        let childTargetIds = target.childTargetIds;
        childTargetIds.push(id);
        setTargetValue("childTargetIds", childTargetIds);
    };

    const deleteChild = id => {
        let childTargetIds = target.childTargetIds.filter(x => x !== id);
        setTargetValue("childTargetIds", childTargetIds);
    };

    const disableChanges = useMemo(() => target.childTargetIds.length > 0 || ((!target.areaId || target.rollupTargetId) && !!target.targetId), [target]);

    return (
        <React.Fragment>
            <DialogTitle>
                {target.description}
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gridGap="10px">
                    <TextField
                        label="Description"
                        value={target.description || ""}
                        error={!target.description}
                        helperText={!target.description && "required"}
                        onChange={(e) => setTargetValue("description", e.target.value)}
                    />
                    <FormControl>
                        <InputLabel shrink>Pillar</InputLabel>
                        <Select
                            disabled={disableChanges}
                            value={target.pillarId || ""}
                            error={!target.pillarId}
                            onChange={(e) => setTargetValue("pillarId", e.target.value)}>
                            {
                                pillars.map(p => <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>)
                            }
                        </Select>
                        {!target.pillarId && <FormHelperText error>required</FormHelperText>}
                    </FormControl>
                    <FormControl>
                        <InputLabel shrink>Area</InputLabel>
                        <Select
                            disabled={disableChanges}
                            displayEmpty
                            value={target.areaId === undefined ? "" : target.areaId}
                            error={target.areaId === undefined}
                            onChange={(e) => setTargetValue("areaId", parseInt(e.target.value))}>
                            <MenuItem key="0" value="0">Roll-up</MenuItem>
                            {
                                areas.map(a => <MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>)
                            }
                        </Select>
                        {target.areaId === undefined && <FormHelperText error>required</FormHelperText>}
                    </FormControl>                     
                    {
                        target.areaId === 0 &&
                        <Box
                            display="flex"
                            flexDirection="column"
                            style={{ border: '1px solid #cecece', padding: '5px', fontSize: '0.8em' }}>
                                {
                                    !target.pillarId &&
                                    "must select pillar"
                                }
                                {
                                    target.pillarId &&
                                    <Box>select targets to roll up</Box>
                                }
                                {
                                    target.childTargetIds.map(id => {
                                        let target = targets.find(t => t.targetId === id);
                                        return (
                                            <Box display="flex" key={id}>
                                                <Box flexGrow={1}>{target.description}</Box>
                                                <Box><IconButton onClick={() => deleteChild(id)}><DeleteIcon /></IconButton></Box>
                                            </Box>
                                        );
                                    })
                                }
                                {
                                    availablePillarTargets.map(target =>
                                        <Box display="flex" key={target.targetId}>
                                            <Box flexGrow={1}>{target.description}</Box>
                                            <Box><IconButton onClick={() => addChild(target.targetId)}><AddIcon /></IconButton></Box>
                                        </Box>
                                    )
                                }
                              
                        </Box>
                    }
                    <FormControl>
                        <InputLabel shrink>Target Type</InputLabel>
                        <Select
                            disabled={disableChanges}
                            value={target.targetType}
                            onChange={(e) => setTargetValue("targetType", parseInt(e.target.value))}>
                            <MenuItem value="0">Number</MenuItem>
                            <MenuItem value="1">Percent</MenuItem>
                            <MenuItem value="2">Money</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={target.isBalanceScoreCard || false}
                                onChange={(e) => setTargetValue("isBalanceScoreCard", e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Is Balance Scorecard"
                    />
                </Box>
            </DialogContent>
        </React.Fragment>
    )
};

const mapStateToProps = state => ({ targetState: state.targetReducer });

const mapDispatchToProps = dispatch => {
    return {
        setTargetValue: (field, value) => dispatch(setTargetValueAction(field, value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTargetInfo);