import * as msalN from '@azure/msal-browser';
import store from '../store/index';
import axios from './axios';
import { setCheckingUserSession, setUser } from '../store/actions/userActions';

class AuthService {
    constructor() {
        this.loginRequest = {
            scopes: [],
        };

        this.profileRequest = {
            scopes: ['User.Read'],
        };

        this.loginRedirectRequest = {
            ...this.loginRequest,
            redirectStartPage: window.location.pathname,
        };

        this.profileRedirectRequest = {
            ...this.profileRequest,
            redirectStartPage: window.location.pathname,
        };

        this.silentProfileRequest = {
            scopes: ['openid', 'profile', 'User.Read', 'offline_access'],
            forceRefresh: true,
        };
    }

    initialise() {
        return new Promise((resolve, reject) => {
            const configState = store.getState().configReducer;

            const msalConfig = {
                auth: {
                    clientId: configState.serverValues.azureClientId,
                    authority:
                        'https://login.microsoftonline.com/ce5ded0d-f6e3-4aa2-8388-54c0b72b34c6',
                    navigateToLoginRequestUrl: false,
                    cacheLocation: 'localStorage',
                    redirectUri: window.location.origin,
                    postLogoutRedirectUri: window.location.origin,
                },
            };

            this.myMSALObj = new msalN.PublicClientApplication(msalConfig);
            resolve();
        });
    }

    logout() {
        localStorage.removeItem('tokenResponse');
        const logOutRequest = {
            account: this.account,
        };

        this.myMSALObj.logout(logOutRequest);
    }

    getAccount() {
        const currentAccounts = this.myMSALObj.getAllAccounts();
        if (currentAccounts && currentAccounts.length > 0)
            // TODO: Add choose account code here
            return currentAccounts[0];

        return null;
    }

    setAccount(account) {
        this.account = account;
    }

    loginRedirect() {
        return this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }

    handleResponse(response) {
        if (response) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.idToken;
            localStorage.setItem('tokenResponse', JSON.stringify(response));
            this.setAccount(response.account);
        }
        store.dispatch(setUser(response));
    }

    async handleAuthCallback() {
        try {
            const callbackTokenResponse = await this.myMSALObj.handleRedirectPromise();
            if (callbackTokenResponse) {
                this.handleResponse(callbackTokenResponse);
            } else {
                this.initialiseUserSession();
            }
        } catch (error) {
            throw error;
        }
    }

    async initialiseUserSession() {
        // only try to resume session if user has accounts in AD cache
        const account = this.getAccount();
        if (account) {
            try {
                this.silentProfileRequest.account = account;
                const silentTokenResponse = await this.myMSALObj.acquireTokenSilent(
                    this.silentProfileRequest
                );
                this.handleResponse(silentTokenResponse);
            } catch (error) {
                if (error instanceof msalN.InteractionRequiredAuthError)
                    try {
                        const redirectTokenResponse = await this.myMSALObj.acquireTokenRedirect(
                            this.profileRedirectRequest
                        );
                        this.handleResponse(redirectTokenResponse);
                    } catch (error) {
                        console.error(error);
                    }
            }
        } else {
            // user is not logged in
            store.dispatch(setCheckingUserSession(false));
        }
    }
}

const auth = new AuthService();

export default auth;
