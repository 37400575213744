import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import {
    FormControl,
    MenuItem,
    Select,
    TextField,
    InputLabel
} from "@material-ui/core";


const InsertSection = ({ editor, sections }) => {

    const [filter, setFilter] = useState("");
    const insertSection = (sectionKey) => {
        if (sectionKey) {
            setFilter("");
            editor.execute("insertSection", { key: sectionKey });
            editor.editing.view.focus();
        }
    };

    const uniqueSectionKeys = useMemo(() => {
        return [...new Set(sections.map(x => x.key))];
    }, [sections]);

    const filteredSectionKeys = useMemo(() => {

        let term = filter.trim().toLowerCase();

        if (!term)
            return uniqueSectionKeys;

        return uniqueSectionKeys.filter(s => s.toLowerCase().includes(term));

    }, [uniqueSectionKeys, filter]);

    return (
        <FormControl variant="outlined" margin="dense">
            <InputLabel>Insert Section</InputLabel>
            <Select
                style={{width: '250px'}}
                displayEmpty
                value=""
                label="Insert Section"
                fullWidth
                onChange={e => insertSection(e.target.value)}
            >
                <MenuItem>
                    <TextField
                        onKeyDown={e => { e.stopPropagation(); }}
                        onClick={e => { e.stopPropagation(); }}
                        fullWidth
                        value={filter}                        
                        onChange={e => setFilter(e.target.value)}
                    />
                </MenuItem>                
                {
                    filteredSectionKeys.map(s => <MenuItem key={s} value={s}>{s}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
};

const mapStateToProps = (state) => ({ sections: Object.values(state.configReducer.sections).sort((a, b) => (a.key === b.key) ? 0 : (a.key < b.key) ? -1 : 1) });

export default connect(mapStateToProps)(InsertSection);


