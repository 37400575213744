import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "../../plugins/axios";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

export const fetchHiscoxMembers = createAsyncThunk(
    'registration/fetchHiscoxMembers',
    async () => {
        const response = await axios.get(`${apiUrl}/registration/hiscoxmembers`);
        return response.data;
    },
);

export const fetchSubmissions = createAsyncThunk(
    'registration/fetchRegistrations',
    async () => {
        const response = await axios.get(`${apiUrl}/registration/submissions`);
        return response.data;
    },
);

export const dismissSubmission = createAsyncThunk(
    'registration/dismissSubmission',
    async (id) => {
        await axios.post(`${apiUrl}/registration/dismiss/${id}`);
        return id;
    },
)

export const registrationSlice = createSlice({
    name: 'registrations',
    initialState: {
        fetchingHiscoxMembers: false,
        hiscoxMembers: [],
        fetchingSubmissions: false,
        submissions: []
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHiscoxMembers.pending, (state, action) => {
            state.fetchingHiscoxMembers = true;
        });

        builder.addCase(fetchHiscoxMembers.fulfilled, (state, action) => {
            state.hiscoxMembers = action.payload;
            state.fetchingHiscoxMembers = false;
        });

        builder.addCase(fetchHiscoxMembers.rejected, (state, action) => {
            state.fetchingHiscoxMembers = false;
        });


        builder.addCase(fetchSubmissions.pending, (state, action) => {
            state.fetchingSubmissions = true;
        });

        builder.addCase(fetchSubmissions.fulfilled, (state, action) => {
            state.submissions = action.payload;
            state.fetchingSubmissions = false;
        });

        builder.addCase(fetchSubmissions.rejected, (state, action) => {
            state.fetchingSubmissions = false;
        });


        builder.addCase(dismissSubmission.pending, (state, action) => {
            state.fetchingSubmissions = true;
        });

        builder.addCase(dismissSubmission.fulfilled, (state, action) => {
            state.submissions = state.submissions.filter(s => s.id !==  action.payload);
            state.fetchingSubmissions = false;
        });

        builder.addCase(dismissSubmission.rejected, (state, action) => {
            state.fetchingSubmissions = false;
        });
    }
})

export default registrationSlice.reducer;


