import { useMutation, useQueryClient } from "react-query";
import queryKeys from "../constants/queryKeys";
import documentService from "../services/documentService";

const useAddAccountFile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ formData, accountId, fileTypeHS, documentType }) => documentService.uploadAccountFile(formData, accountId, fileTypeHS, documentType),
    {
      onSuccess: () => queryClient.invalidateQueries(queryKeys.accountFiles),
    }
  );
};

export default useAddAccountFile;
