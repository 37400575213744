import axios from "../plugins/axios";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const fetchMyVisits = async ({ page, rowsPerPage }) => {
  try {
    const { data: myVisits } = await axios.get(
      `${apiUrl}/myvisits?page=${page}&rows=${rowsPerPage}`
    );
    return myVisits;
  } catch (e) {
    return Promise.reject(
      e?.data?.message ?? "Could not fetch visits for user."
    );
  }
};

const fetchVisitSummary = async () => {
  try {
    const response = await axios.get(`${apiUrl}/visitsummary`);
    return response.data;
  } catch (e) {
    console.error(e);
    return Promise.reject(
      e?.message || "Could not fetch visit automation window."
    );
  }
};

const timesheetService = {
  fetchMyVisits,
  fetchVisitSummary,
};

export default timesheetService;
