import React, { useEffect, useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../store/reducers/configReducer";
import "../../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "../../plugins/axios";
import MyCKEditor from "../../ckeditor/MyCKEditor";
import {
    getNodesByType,
    setPlaceholderValue,
    setSectionContent,
} from "../../ckeditor/ckEditorUtils";
import QuestionCard from "./QuestionCard";
import axios_raw from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ckcss from "../../ckeditor/ck-content.css";
import documentService from "../../services/documentService";
import { setSnackAction } from "../../store/actions/snackActions";
import { Alert } from "@material-ui/lab";
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    TextField
} from "@material-ui/core";

import {
    Gavel as GavelIcon,
    Publish as PublishIcon,
} from "@material-ui/icons";
import { ReactComponent as DocImage } from "../../images/file_doc.svg";
import { ReactComponent as PdfImage } from "../../images/file_pdf.svg";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const TemplateDocument = ({ documentTemplateId, setSnack, sections, getSection }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [editor, setEditor] = useState(null);
    const [template, setTemplate] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [sectionQuestions, setSectionQuestions] = useState([]);
    const [stage, setStage] = useState(1);
    const [publishing, setPublishing] = useState(false);
    const [savedAnswers, setSavedAnswers] = useState();

    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [accountOptions, setAccountOptions] = useState([]);
    const [accountText, setAccountText] = useState("");
    const [selectedAccount, setSelectedAccount] = useState();

    const [requireConfirmation, setRequireConfirmation] = useState(false);

    const handleAccountChange = (e, v) => {
        setSelectedAccount({
            accountId: v && v.id,
            accountName: v && v.text,
            externalId: v && v.value,
        });
    };

    useEffect(() => {

        const getAnswers = async () => {
            var answers = await documentService.getAnswers(selectedAccount.externalId);
            setSavedAnswers(answers);
        };

        if (selectedAccount) {
            getAnswers();
        }
    }, [selectedAccount]);

    useEffect(() => {

        let active = true;

        if (!accountText || accountText.length < 3) {
            setAccountOptions([]);
            return undefined;
        }

        (async () => {
            const clients = (await axios.get(`${apiUrl}/getclients/` + encodeURIComponent(accountText))).data;

            if (active) {
                setAccountOptions(clients);
            }
        })();

        return () => {
            active = false;
        };
    }, [autocompleteOpen, accountText]);

    const styles = { targetDiv: { height: "calc(100vh - 180px)" } };

    const getTemplate = useCallback(async () => {
        setIsLoading(true);
        var response = await axios.get(
            `${apiUrl}/gettemplate/${documentTemplateId}`
        );
        setTemplate(response.data);
        setIsLoading(false);
    }, [documentTemplateId]);

    useEffect(() => {

        if (documentTemplateId && !template) {
            getTemplate();
        }
    }, [getTemplate, documentTemplateId, template]);

    const populateQuestions = useCallback(() => {
        if (editor && savedAnswers) {
            editor.model.change((writer) => {
                const root = editor.model.document.getRoot();
                const placeholderElements = getNodesByType(writer, "placeholder", root);

                const usedKeys = [];
                const tempQuestions = [];
                for (const e of placeholderElements) {
                    const obj = { ...e.getAttribute("placeholder"), type: "placeholder" };
                    obj.value = e.getAttribute("value") || savedAnswers[obj.key];
                    if (!usedKeys.includes(obj.key)) {
                        usedKeys.push(obj.key);
                        tempQuestions.push(obj);
                        setPlaceholderValue(editor, obj.key, obj.value);
                    }
                }

                setQuestions(tempQuestions);
            });
        }
    }, [editor, savedAnswers]);

    useEffect(() => {
        populateQuestions();

    }, [populateQuestions]);

    useEffect(() => {

        if (!template || !editor)
            return;

        let html = template.html;

        editor.setData(html);
    }, [template, editor]);

    useEffect(() => {

        if (editor && template && savedAnswers) {

            let keysToUpdate = Object.keys(savedAnswers);

            editor.model.change((writer) => {
                const root = editor.model.document.getRoot();
                const usedKeys = [];

                const tempQuestions = [];
                const sectionElements = getNodesByType(writer, "htmlSection", root);
                for (const e of sectionElements) {
                    const htmlAttributes = e.getAttribute("htmlAttributes");
                    const key = htmlAttributes.attributes["data-key"];

                    if (!usedKeys.includes(key)) {
                        usedKeys.push(key);

                        let matchedSections = sections.filter(x => x.key === key);

                        for (let matchedSection of matchedSections) {
                            if (matchedSection.isMandatory)
                                keysToUpdate.push(key);

                            tempQuestions.push({
                                key: key,
                                id: matchedSection.sectionId,
                                type: "section",
                                dependsOnSectionId: matchedSection.dependsOnSectionId,
                                isMandatory: matchedSection.isMandatory,
                                value: matchedSections.length === 1
                                    ? savedAnswers[key]
                                    // eslint-disable-next-line eqeqeq
                                    : savedAnswers[key] == matchedSection.sectionId ? "Yes" : (savedAnswers[key] && "No")
                            });
                        }
                    }
                }

                setSectionQuestions(tempQuestions);                

                for (let tempQuestion of tempQuestions) {
                    if (tempQuestion.dependsOnSectionId) {
                        let parentQuestion = tempQuestions.find(x => x.id === tempQuestion.dependsOnSectionId);

                        if (parentQuestion.value !== "Yes") {
                            tempQuestion.hidden = true;
                            keysToUpdate.push(tempQuestion.key);
                        }                            
                    }
                }

                doUpdates(keysToUpdate, tempQuestions);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, template, setSectionQuestions, savedAnswers]);

    const displayQuestions = useMemo(() => {
        return stage === 1 ? sectionQuestions.filter(x => !x.hidden) : questions;
    }, [stage, sectionQuestions, questions]);   

    const progress = useMemo(() => {

        let countableQuestions = displayQuestions.filter(q => q.type === 'placeholder' || sections.some(s => s.key === q.key && !s.isMandatory))

        const total = countableQuestions.length;

        if (total === 0)
            return 100;

        const completed = countableQuestions.filter((m) => m.value);

        return (completed.length * 100) / total;

    }, [displayQuestions, sections]);

    const [pendingUpdates, setPendingUpdates] = useState([]);

    const populateSectionContent = useCallback((key, newQuestions) => {

        let matchedSections = sections.filter(x => x.key === key);
        let firstYes = newQuestions.find(x => x.key === key && (x.value === "Yes" || x.isMandatory));
        let matchedSection = firstYes && matchedSections.find(x => x.sectionId === firstYes.id && !firstYes.hidden);

        let retValue = matchedSection && !matchedSection.html && matchedSection.sectionId;

        if (!retValue) {
            setSectionContent(
                editor,
                key,
                matchedSection
                    ? matchedSection.html || "LOADING ...."
                    : matchedSections.length > 1
                        ? "MULTI CHOICE"
                        : "",
                populateQuestions
            );
        }

        return retValue;

    }, [editor, populateQuestions, sections]);

    useEffect(() => {

        if (pendingUpdates.length > 0) {
            let actioned = [];
            for (let id of pendingUpdates) {

                let s = sections.find(x => x.sectionId === id);

                if (s.html) {
                    actioned.push(id);
                    populateSectionContent(s.key, sectionQuestions);
                }
            }

            let updatedPendingUpdates = pendingUpdates.filter(x => !actioned.includes(x));

            if (pendingUpdates.length !== updatedPendingUpdates.length)
                setPendingUpdates(pendingUpdates.filter(x => !actioned.includes(x)));
        }


    }, [sections, pendingUpdates, populateSectionContent, sectionQuestions]);

    const setValue = (type, key, value, id) => {

        let keysToUpdate = [key];

        if (type === "placeholder") {
            setQuestions(questions.map(q => (q.key === key ? { ...q, value } : q)));
            setPlaceholderValue(editor, key, value);
        }

        if (type === "section") {

            let updatedQuestions = sectionQuestions.map(q => (q.id === id ? { ...q, value } : q));

            if (value === "Yes") {
                let sameKeyQuestions = updatedQuestions.filter(x => x.key === key && x.id !== id);
                for (let sameKeyQuestion of sameKeyQuestions)
                    sameKeyQuestion.value = "No";
            }

            let currentSection = sections.find(x => x.sectionId === id);

            let sameQuestionSections = sections.filter(x => x.question === currentSection.question && x.sectionId !== id);
            let sectionIdsToChange = sameQuestionSections.map(x => x.sectionId);
            let sameQuestionQuestions = updatedQuestions.filter(q => sectionIdsToChange.includes(q.id));
            for (let sameQuestionQuestion of sameQuestionQuestions) {
                sameQuestionQuestion.value = value;
                keysToUpdate.push(sameQuestionQuestion.key);
            }

            updatedQuestions = updatedQuestions.map(q => (sectionIdsToChange.includes(q.id) ? { ...q, value } : q));

            setSectionQuestions(updatedQuestions);

            doUpdates(keysToUpdate, updatedQuestions);
        }
    };

    const doUpdates = (keys, questions) => {

        let sectionIds = [];

        for (let k of keys) {
            let sectionId = populateSectionContent(k, questions);
            if (sectionId)
                sectionIds.push(sectionId);
        }

        if (sectionIds.length > 0)
            setPendingUpdates([...pendingUpdates, ...sectionIds]);

        for (let sectionId of sectionIds)
            getSection(sectionId);
    }

    useEffect(() => {

        let changed = false;
        let tempQuestions = [...sectionQuestions];

        let sectionIds = [];

        for (let question of tempQuestions) {
            if (question.dependsOnSectionId) {
                let parentQuestion = tempQuestions.find(x => x.id === question.dependsOnSectionId);
                if (parentQuestion) {
                    let hidden = parentQuestion.value !== "Yes";
                    if (question.hidden !== hidden) {
                        question.hidden = hidden;
                        changed = true;
                        let sectionId = populateSectionContent(question.key, tempQuestions);
                        if (sectionId)
                            sectionIds.push(sectionId);
                    }
                }
                
            }
        }

        if (changed)
            setSectionQuestions(tempQuestions);

        if (sectionIds.length > 0)
            setPendingUpdates([...pendingUpdates, ...sectionIds]);

        for (let sectionId of sectionIds)
            getSection(sectionId);

    }, [getSection, pendingUpdates, populateSectionContent, sectionQuestions]);
    

    const saveAnswers = async () => {
        let answers = {};

        let sectionKeys = sectionQuestions.map(x => x.key);

        sectionKeys = [...new Set(sectionKeys)];

        for (let sectionKey of sectionKeys) {
            let sectionQuestionGroup = sectionQuestions.filter(x => x.key === sectionKey);

            if (sectionQuestionGroup.length === 1) {
                answers[sectionKey] = sectionQuestionGroup[0].value;
            }
            else {
                let chosenQuestion = sectionQuestionGroup.find(x => x.value === "Yes");
                answers[sectionKey] = chosenQuestion?.id;
            }
        }

        for (const question of questions) {
            if (!answers.hasOwnProperty(question.key))
                answers[question.key] = question.value;
        }

        await documentService.saveAnswers(selectedAccount.externalId, answers);
    };

    const downloadPdf = async () => {
        setDownloading(true);

        saveAnswers();

        const margins = editor.config.get('pagination.pageMargins');

        let converterOptions = {
            margin_right: margins.right,
            margin_left: margins.left,
            margin_top: margins.top,
            margin_bottom: margins.bottom,
            footer_html: '<div class="styled-counter"><span class="pageNumber"></span></div>',
            header_and_footer_css: '.styled-counter { font-size: 1em; color: hsl(0, 0%, 60%); text-align: center; margin-bottom:15px;}',
        };

        let response = await axios_raw.post(
            "https://ckeditor-pdf.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert",
            {
                html: `<div class="ck-content">${editor.getData()}</div>`,
                css: ckcss,
                options: converterOptions,
            },
            { responseType: "blob" }
        );

        const blob = new Blob([response.data]);

        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("target", "_blank");
        link.download = `${template.name}.pdf`;
        link.click();

        setDownloading(false);
    };

    const downloadWord = async () => {
        setDownloading(true);

        saveAnswers();

        const margins = editor.config.get('pagination.pageMargins');

        let converterOptions = {
            margin_right: margins.right,
            margin_left: margins.left,
            margin_top: margins.top,
            margin_bottom: margins.bottom,
            footer: [
                {
                    html: '<span></span>',
                    type: 'first'
                },
                {
                    html: '<p><span class="pageNumber"></span></p>',
                    css: 'p { font-size: 1em; color: hsl(0, 0%, 60%); text-align: center; margin-bottom:15px;}'
                }
            ]
        };

        try {
            let response = await axios_raw.post(
                "https://ckeditor-docx.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert",
                {
                    html: `<html><link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Raleway:900"><body><div class="ck-content">${editor.getData()}</div></body></html>`,
                    css: ckcss,
                    options: converterOptions,
                },
                { responseType: "blob" }
            );

            const blob = new Blob([response.data]);

            var link = window.document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("target", "_blank");
            link.download = `${template.name}.docx`;
            link.click();
        }
        finally {
            setDownloading(false);
        }
    };

    const publish = () => {
        setPublishing(true);
    };

    const upload = async (confirmed) => {
        if (!confirmed) {
            let docs = await documentService.fetchManualAndHandbookFiles(
                selectedAccount.accountId
            );

            if (docs.includes(`${template.name}.pdf`)) {
                setRequireConfirmation(true);
                return;
            }
        }

        setRequireConfirmation(false);
        setDownloading(true);

        saveAnswers();

        const margins = editor.config.get('pagination.pageMargins');

        let converterOptions = {
            margin_right: margins.right,
            margin_left: margins.left,
            margin_top: margins.top,
            margin_bottom: margins.bottom,
            footer_html: '<div class="styled-counter"><span class="pageNumber"></span></div>',
            header_and_footer_css: '.styled-counter { font-size: 1em; color: hsl(0, 0%, 60%); text-align: center; margin-bottom:15px;}',
        };

        let response = await axios_raw.post(
            "https://ckeditor-pdf.greenwave-257981d3.westeurope.azurecontainerapps.io/v1/convert",
            {
                html: `<body><div class="ck-content">${editor.getData()}</div></body>`,
                css: ckcss,
                options: converterOptions,
            },
            { responseType: "blob" }
        );

        const blob = new Blob([response.data]);

        var formData = new FormData();
        formData.append("file", blob);
        formData.append("fileName", `Manual and Handbook/${template.name}.pdf`);

        await documentService.uploadAccountFile(
            formData,
            selectedAccount.accountId,
            1,
            0
        );
        setPublishing(false);
        setSnack("File published", "success");
        setDownloading(false);
    };

    if (isLoading)
        return <h1>Loading ...</h1>;

    if (!template)
        return <h1>No template found ...</h1>;

    return (
        <Grid container spacing={3} style={styles.targetDiv}>
            <Grid item xs={3} style={{ height: "100%" }}>
                {
                    selectedAccount &&
                    <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
                        <Alert severity="info" onClose={() => setSelectedAccount(null)}>
                            {selectedAccount.accountName}
                        </Alert>
                        <Box mb={3}>
                            <Typography variant="h5">
                                Please complete the steps below to complete your document
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>
                        {
                            stage === 1 && progress === 100 &&
                            <Box>
                                <Button variant="contained" onClick={() => setStage(2)} disabled={pendingUpdates.length > 0}>
                                    Next
                                </Button>
                            </Box>
                        }
                        {
                            stage === 2 &&
                            <Box>
                                <Button variant="contained" onClick={() => setStage(1)}>
                                    Back
                                </Button>
                            </Box>
                        }
                        <Box flexGrow={1} style={{ height: 0 }}>
                            <PerfectScrollbar>
                                <Paper style={{ padding: "8px" }}>
                                    {displayQuestions.map((q) => (
                                        <QuestionCard
                                            key={`${q.key}-${q.id}`}
                                            question={q}
                                            setValue={(value) => setValue(q.type, q.key, value, q.id)}
                                        />
                                    ))}
                                </Paper>
                            </PerfectScrollbar>
                        </Box>
                    </Box>
                }
                {
                    !selectedAccount &&
                    <Box display="flex" flexDirection="column">
                        <Typography variant="h6" gutterBottom>
                            Select an account
                        </Typography>
                        <Autocomplete
                            freeSolo
                            open={autocompleteOpen}
                            onOpen={() => setAutocompleteOpen(true)}
                            onClose={() => setAutocompleteOpen(false)}
                            getOptionSelected={(option, value) =>
                                option.value === value.value
                            }
                            getOptionLabel={(option) => option.text}
                            onChange={handleAccountChange}
                            options={accountOptions}
                            onInputChange={(e, val) => setAccountText(val)}
                            renderInput={(params) => {
                                params.inputProps.autoComplete = "account-documents";
                                return <TextField {...params} fullWidth />;
                            }}
                        />
                    </Box>
                }
            </Grid>
            <Grid item xs={9} style={{ height: "100%" }}>
                <Box display="flex" flexDirection="column" style={{ height: "100%" }}>
                    <Box mb={3} display="flex" justifyContent="space-between">
                        <Box display="flex">
                            <GavelIcon fontSize="small" />
                            &nbsp;
                            <Typography variant="h5">{template.name}</Typography>
                        </Box>
                    </Box>
                    <Box
                        mb={2}
                        flexGrow={1}
                        style={{ height: 0 }}
                        display="flex"
                        flexDirection="column"
                    >
                        <MyCKEditor type="document" setEditor={setEditor} contained editor={editor} />
                    </Box>
                    {
                        progress === 100 && stage === 2 &&
                        <Box display="flex" justifyContent="flex-end" className="ew">
                            <Box>
                                <Button
                                    title="Publish to myWorkNest"
                                    disabled={downloading}
                                    onClick={publish}
                                >
                                    <PublishIcon className="ew grow" fontSize="large" />
                                </Button>
                            </Box>
                            <Button
                                title="Download Word Document"
                                disabled={downloading}
                                onClick={downloadWord}
                            >
                                <DocImage title="Download Word Document" className="ew grow docImage" />
                            </Button>
                            <Button
                                title="Download PDF Document"
                                disabled={downloading}
                                onClick={downloadPdf}
                            >
                                <PdfImage title="Download PDF Document" className="ew grow docImage" />
                            </Button>
                        </Box>
                    }
                </Box>
            </Grid>
            <Dialog
                open={publishing}
                onClose={() => setPublishing(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>PUBLISH {template.name}</DialogTitle>
                <DialogActions>
                    {
                        selectedAccount?.accountId && !requireConfirmation &&
                        <Button
                            disabled={downloading}
                            style={{ width: "100%", marginTop: "10px" }}
                            onClick={() => upload(false)}
                            variant="contained"
                            color="primary"
                        >
                            Publish to {selectedAccount.accountName}
                        </Button>

                    }
                    {
                        requireConfirmation &&
                        <Button
                            disabled={downloading}
                            style={{ width: "100%", marginTop: "10px" }}
                            onClick={() => upload(true)}
                            variant="contained"
                            color="primary"
                        >
                            Confirm Overwrite existing file
                        </Button>

                    }
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    documentTemplateId: state.configReducer.documentTemplateId,
    sections: Object.values(state.configReducer.sections)
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setSnack: (message, severity) => dispatch(setSnackAction(message, severity))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDocument);