import React from "react";
import "./ckeditor.css";
import { connect } from "react-redux";
import "../vendor/perfect-scrollbar.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";

import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily.js";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import ImageBlock from "@ckeditor/ckeditor5-image/src/imageblock.js";
import PictureEditing from "@ckeditor/ckeditor5-image/src/pictureediting.js";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert.js";
import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import ListProperties from "@ckeditor/ckeditor5-list/src/listproperties";
import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak.js";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat.js";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript.js";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript.js";
import Table from "@ckeditor/ckeditor5-table/src/table.js";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount.js";

import PlaceholderEditing from "./PlaceholderEditing";
import SectionHolderEditing from "./SectionHolderEditing";

const commonPlugins = [
    Alignment,
    Autoformat,
    Base64UploadAdapter,
    Bold,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    HorizontalLine,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageBlock,
    PictureEditing,
    ImageInsert,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    ListProperties,
    PageBreak,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCellProperties,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline,
    WordCount,
    PlaceholderEditing,
];

const toolbar = [
    //"previousPage",
    //"nextPage",
    //"pageNavigation",
    //"|",
    "heading",
    "|",
    "fontSize",
    "fontFamily",
    "|",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "underline",
    "strikethrough",
    "|",
    "alignment",
    "|",
    "numberedList",
    "bulletedList",
    "|",
    "outdent",
    "indent",
    "|",
    "todoList",
    "link",
    "imageUpload",
    "insertTable",
    "|",
    "undo",
    "redo",
    "horizontalLine",
    "pageBreak",
    "superscript",
    "subscript",
    "removeFormat",
];

const MyCKEditor = ({ setEditor, placeholders, sections, type, onChange, editor }) => {
    const colors = [
        { color: "#000000", label: "Black" },
        { color: "#4D4D4D", label: "Dim grey" },
        { color: "#999999", label: "Grey" },
        { color: "#E6E6E6", label: "Light grey" },
        { color: "#FFFFFF", label: "White" },
        { color: "#E64C4C", label: "Red" },
        { color: "#E6994C", label: "Orange" },
        { color: "#E6E64C", label: "Yellow" },
        { color: "#99E64C", label: "Light Green" },
        { color: "#4CE64C", label: "Green" },
        { color: "#4CE699", label: "Aquamarine" },
        { color: "#4CE6E6", label: "Turquoise" },
        { color: "#4C99E6", label: "Light blue" },
        { color: "#4C4CE6", label: "Blue" },
        { color: "#994CE6", label: "Purple" },
    ];

    const plugins = [...commonPlugins];

    switch (type) {
        case "template":
            plugins.push(SectionHolderEditing);
            break;
        case "section":
            break;
        case "document":
            //plugins.push(SectionHolderEditing);
            break;
        default:
            break;
    }

    const editorConfiguration = {
        plugins: plugins,
        licenseKey: "upOSgYiWLBkSU4Z8M9ev18F4TZhOll1FK412R6zkEkdYRmFtfxuEM0sZP2wenv6FxqcwDQc+UfTGQQ==",
        toolbar: toolbar,
        table: {
            contentToolbar: [
                "tableColumn",
                "tableRow",
                "mergeTableCells",
                "tableProperties",
                "tableCellProperties",
            ],
        },
        image: {
            styles: [
                'alignCenter',
                'alignLeft',
                'alignRight'
            ],
           toolbar: [
                'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', 'imageStyle:side', '|',
                'resizeImage'
            ],
            resizeOptions: [
                {
                    name: 'resizeImage:original',
                    value: null,
                    label: 'Original'
                },
                {
                    name: 'resizeImage:10',
                    value: '10',
                    label: '10%'
                },
                {
                    name: 'resizeImage:20',
                    value: '20',
                    label: '20%'
                },
                {
                    name: 'resizeImage:47',
                    value: '47',
                    label: '47%'
                }
            ]

        },
        fontSize: {
            options: [
                {
                    title: '9',
                    model: '9pt'
                },
                {
                    title: '9.5',
                    model: '9.5pt'
                },
                {
                    title: '10',
                    model: '10pt'
                },
                {
                    title: '10.5',
                    model: '10.5pt'
                },
                {
                    title: '11',
                    model: '11pt'
                },
                'default',
                {
                    title: '12',
                    model: '12pt'
                },
                {
                    title: '28',
                    model: '28pt'
                },
                {
                    title: '36',
                    model: '36pt'
                }
            ]
        },
        fontColor: { colors },
        fontBackgroundColor: { colors },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true,
            },
        },
        htmlSupport: {
            allow: [
                {
                    name: "section",
                    styles: true,
                    classes: ["sectionholder"],
                    attributes: { "data-key": true },
                },
            ],
        },
        pagination: {
            pageWidth: "21cm",
            pageHeight: "29.7cm",
            pageMargins: {
                top: "20mm",
                bottom: "30mm",
                right: "25mm",
                left: "25mm",
            },
        },
    };

    if (placeholders.length === 0 || sections.length === 0)
        return null;

    return (
        <div className="document-editor">
            <div className="document-editor__toolbar"></div>
            <div className="document-editor__editable-container">
                <CKEditor
                    editor={DecoupledEditor}
                    config={editorConfiguration}
                    onReady={(editor) => {
                        const toolbarContainer = document.querySelector(".document-editor__toolbar");
                        toolbarContainer.appendChild(editor.ui.view.toolbar.element);

                        setEditor && setEditor(editor);

                        //CKEditorInspector.attach('editor', editor);
                    }}
                    onError={(error, { willEditorRestart }) => {
                        if (willEditorRestart) {
                            editor.ui.view.toolbar.element.remove();
                        }
                        var propertyNames = Object.getOwnPropertyNames(error);
                        var descriptor;
                        for (var property, i = 0, len = propertyNames.length; i < len; ++i) {
                            property = propertyNames[i];
                            descriptor = Object.getOwnPropertyDescriptor(error, property);
                            console.log("error", property, descriptor);
                        }
                    }}
                    onBlur={(event, editor) => {
                        const data = editor.getData();
                        onChange && onChange(data);
                    }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    placeholders: state.configReducer.placeholders,
    sections: Object.values(state.configReducer.sections),
});

export default connect(mapStateToProps)(MyCKEditor);
