import { useQuery } from "react-query";
import queryKeys from "../../constants/queryKeys";
import documentService from "../../services/documentService";

const useAccountFiles = (accountId, fileTypeHS, documentType) =>
  useQuery(
    [queryKeys.accountFiles, fileTypeHS, accountId],
      () => documentService.fetchAccountFiles(accountId, fileTypeHS, documentType),
    {
      enabled: !!accountId || !!fileTypeHS,
    }
  );

export default useAccountFiles;
