import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { setTargetValueAction } from '../../store/actions/targetActions';
import Alert from '@material-ui/lab/Alert';

import {
    Box,
    DialogTitle,
    DialogContent,
    TextField,
} from "@material-ui/core";


const EditTargetLimits = ({ targetState, setTargetValue }) => {

    const { editingTarget: target, targets } = targetState;

    const childTargets = useMemo(() => targets.filter(t => t.rollupTargetId === target.targetId), [target.targetId, targets]);

    const missingTargets = useMemo(() => childTargets.filter(t => !t.target), [childTargets]);

    const disableChanges = useMemo(() => target.areaId === 0, [target.areaId]);

    return (
        <React.Fragment>
            <DialogTitle>
                {target.description}
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gridGap="10px">
                    {missingTargets.length > 0 && <Alert severity="error">{missingTargets.length} out of {childTargets.length} targets are missing target value.</Alert>}
                    <TextField
                        label="Owner Email"
                        value={target.ownerEmail || ""}
                        error={!target.ownerEmail}
                        helperText={!target.ownerEmail && "required"}
                        onChange={(e) => setTargetValue("ownerEmail", e.target.value)}
                    />
                    <TextField
                        disabled={disableChanges}
                        type="number"
                        label="Red Lower"
                        value={target.redLower || ""}
                        onChange={(e) => setTargetValue("redLower", e.target.value)}
                    />
                    <TextField
                        disabled={disableChanges}
                        type="number"
                        label="Amber Lower"
                        value={target.amberLower || ""}
                        onChange={(e) => setTargetValue("amberLower", e.target.value)}
                    />
                    <TextField
                        disabled={disableChanges}
                        type="number"
                        label="Target"
                        value={target.target == null ? "" : target.target}
                        error={target.target !== 0 && !target.target && !disableChanges}
                        helperText={target.target !== 0 && !target.target && !disableChanges && "required"}
                        onChange={(e) => setTargetValue("target", e.target.value)}
                    />
                    <TextField
                        disabled={disableChanges}
                        type="number"
                        label="Amber Upper"
                        value={target.amberUpper || ""}
                        onChange={(e) => setTargetValue("amberUpper", e.target.value)}
                    />
                    <TextField
                        disabled={disableChanges}
                        type="number"
                        label="Red Upper"
                        value={target.redUpper || ""}
                        onChange={(e) => setTargetValue("redUpper", e.target.value)}
                    />
                </Box>
            </DialogContent>           
        </React.Fragment>
    )
};

const mapStateToProps = state => ({ targetState: state.targetReducer });

const mapDispatchToProps = dispatch => {
    return {
        setTargetValue: (field, value) => dispatch(setTargetValueAction(field, value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTargetLimits);