import * as types from '../constants';

export function setPillarIdAction(pillarId) {
    return {
        type: types.SET_TARGET_PILLAR_ID,
        pillarId
    };
}

export function setAreaIdAction(areaId) {
    return {
        type: types.SET_TARGET_AREA_ID,
        areaId
    };
}


export function setSelectedDateAction(date) {
    return {
        type: types.SET_SELECTED_DATE,
        date: date,
    };
}

export function setViewAction(view) {
    return {
        type: types.SET_TARGET_VIEW,
        view
    };
}

export function setTargetValueAction(field, value) {
    return {
        type: types.SET_TARGET_VALUE,
        field: field,
        value
    };
}

export function setEditingAction(targetId, type) {
    return {
        type: types.SET_TARGET_EDITING,
        targetId,
        editingType: type
    };
}