import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import {
    dashboard as dashboardRoutes,
    proto as protoRoutes
} from "./index";
import DashboardLayout from "../layouts/DashboardLayout";
import ProtoLayout from "../layouts/ProtoLayout";
import { Box } from "@material-ui/core";
import history from "./history";
import store from '../store/index';

const childRoutes = (Layout, routes) => {

    const roles = store.getState().userReducer.user.idTokenClaims.roles || [];

    return routes.map(({ children, path, component: Component }, index) =>
        children ? (
            // Route item with children
            children.filter(r => !r.requiresRole || roles.includes(r.requiresRole)).map(({ path, component: Component }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    );

}
    

const Routes = () => (

    <Box display="flex">
        <Router history={history}>
            <Box flexGrow={1} style={{width: '100%'}}>
                <Switch>
                    {childRoutes(DashboardLayout, dashboardRoutes)}
                    {childRoutes(ProtoLayout, protoRoutes)}
                </Switch>
            </Box>         
        </Router>
    </Box>
);


export default Routes;
