import Command from "@ckeditor/ckeditor5-core/src/command";

export default class SectionHolderCommand extends Command {
  execute(section) {
    const editor = this.editor;
    const selection = editor.model.document.selection;

    editor.model.change((writer) => {
      const sectionHolder = writer.createElement("sectionholder", {
        ...Object.fromEntries(selection.getAttributes()),
        section: section,
      });

      editor.model.insertContent(sectionHolder);

      writer.setSelection(sectionHolder, "on");
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;

    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      "sectionholder"
    );

    this.isEnabled = allowedIn !== null;
  }
}
